import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';

import Notification from 'components/modal/Notification';
import OrderOverviewActions, {
  TOrderOverviewFilter,
} from 'actions/workflow/order/OrderOverviewActions';
import DialogCancelOrder from 'page/workflow/order/components/DialogCancelOrder';
import { Grid } from 'components/grid';
import Form from 'components/form/Form';
import Text from 'components/form/input/Text';
import Radio from 'components/form/input/Radio';
import { DateRangeLegacy } from 'components/form/dateRange';
import TextCalendar from 'components/form/input/TextCalendar';
import radioOptions from 'components/project/common/RadioOptions';
import StateRolesDropdown from 'components/project/dropdown/StateRolesDropdown';
import LocationRolesDropdown from 'components/project/dropdown/LocationRolesDropdown';
import ExamTypeDropdown from 'components/project/dropdown/ExamTypeDropdown';
import FacilityRolesDropdown from 'components/project/dropdown/FacilityRolesDropdown';
import RadiologyDropdown from 'components/project/dropdown/RadiologyDropdown';
import ClinicianGroupDropdown from 'components/project/dropdown/ClinicianGroupDropdown';
import PlaceOfServiceRolesDropdown from 'components/project/dropdown/PlaceOfServiceRolesDropdown';
import { DropdownPureDivision } from 'components/project/dropdown/Division';
import UserProfileStore from 'stores/UserProfileStore';
import VisitInformationPage from 'page/workflow/order/VisitInformationPage';
import {
  DialogScheduledServiceDate,
  FormModel as ScheduleServiceDateFormModel,
} from './components/DialogScheduleServiceDate/DosModal';
import DialogPhone from 'page/components/DialogFaxPhone/DialogPhone';
import DialogFax from 'page/components/DialogFaxPhone/DialogFax';
import DialogPhysicianAssignment from 'page/workflow/order/components/DialogPhysicianAssignment';
import DialogAssignTechnician from 'page/workflow/order/components/DialogAssignTechnician';
import DialogTranscription from 'page/workflow/order/components/dialogTranscription';
import EligibilityManagerPageActions from 'actions/billing-coding/EligibilityManagerPageActions';
import VisitScheduleActions from 'actions/workflow/order/VisitScheduleActions';
import SignOrdersPageActions from 'actions/clinician-manager/sign-orders/SignOrdersPageActions';
import OrderFormPageActions from 'actions/workflow/order/OrderFormPageActions';
import DialogVisitNotes, { FormModel } from 'page/workflow/order/visitNotes';
import DialogAuditLog from 'page/workflow/order/components/DialogAuditLog';
import DialogAddendum from 'page/workflow/order/components/DialogAddendum';
import OrderMessageDialog from 'page/components/messages/OrderMessageDialog';
import { ExamExportLink } from 'page/components/ExportLink';
import DialogAdditionalPatientIds, {
  PatientIdOptions,
} from 'page/workflow/order/components/DialogAdditionalPatientIds';
import SupOneDay from 'page/workflow/order/SupOneDay';
import DialogTranscriptionReports from 'page/workflow/order/order/DialogTranscriptionReports';
import { TechnologistDropdown } from 'components/project/dropdown/TechnologistDropDown';
import { TPagination } from 'components/grid/GridTypes';
import { Button, IconButton } from 'components/button';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import { storeAssignedOrders } from 'stores/_mobx/workflow/order/assignedOrders';
import { storeGrid } from 'stores/_mobx/grid';
import AccessUtils from 'utils/AccessUtils';
import { getPagination } from 'utils/getPagination';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { SpinnerFixed } from 'components/spinner';
import DialogConfirm from 'components/modal/dialogConfirm';
import { ControlsLayout } from 'components/layout';
import Tooltip from 'components/tooltip';
import { DiagnosticViewingLink } from 'page/components/DiagnosticViewing';
import VisitNotesActions from 'actions/workflow/order/VisitNotesActions';
import {
  dateToLocalTimezone,
  getDateRangeBounds,
  getDateString,
  sortByDate,
} from 'utils/DateUtils';
import { storeFax, FaxReportType } from 'stores/_mobx/workflow/order/fax';
import { exportExam } from 'stores/_mobx/export';
import { PERMISSIONS_ORDER_ACTIONS } from 'constant/pagesId/other';
import TechnicianPreAssignValidationDialog from './components/technicianPreAssignValidationDialog';

const SOURCE_OPTIONS = [
  { value: 'Online', label: 'Online' },
  { value: 'Company', label: 'Company' },
  { value: 'HL7', label: 'HL7' },
  { value: 'All', label: 'All' },
];

const SIGNED_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'Y', label: 'Signed' },
  { value: 'N', label: 'Unsigned' },
];

const checkVaccinationRequiredFlag = (
  orders: TSignOrderItem[],
  selected: number[]
) => {
  const isVaccinationRequired = selected.some((id) => {
    const order = orders.find((order) => order.refid === id);

    return order?.isVaccinationRequired;
  });

  return isVaccinationRequired;
};

const validateBeforeAssignment = (
  orders: TSignOrderItem[]
): Map<string, string | number>[] => {
  const errors = orders.map((order) => {
    const error = new Map<string, string | number>();
    if (Number(order.facilityid) && order.facility_stat !== 'A') {
      error.set('Facility:', 'not active');
    }
    if (!Number(order.facilityid)) {
      error.set('Facility:', 'required');
    }
    if (!Number(order.examtypeid) || !order.examtype) {
      error.set('Modality:', 'required');
    }
    if (!order.cptcount) {
      error.set('Procedures:', 'required');
    }
    if (!dateToLocalTimezone({ date: order.dos })) {
      error.set('DOS:', 'required');
    }
    if (!order.exam_symptoms) {
      error.set('Reason for exam:', 'required');
    }
    if (!Number(order.phyuserinfoid)) {
      error.set('Physician:', 'required');
    }
    if (error.size) {
      error.set('id:', order.refid);
      error.set('patientFullName', order.patientnm);
      return error;
    }
    return null;
  });

  return errors.filter(Boolean);
};

export const filterInitValues: TFilter = {
  source: 'All',
  isSigned: '',
  faceSheet: '',
  firstname: '',
  lastname: '',
  examType: [],
  facility: FacilityRolesDropdown.getFacilityId()
    ? [FacilityRolesDropdown.getFacilityId()]
    : [],
  technologist: '',
  state: StateRolesDropdown.getStateId()
    ? [StateRolesDropdown.getStateId()]
    : [],
  division: 0,
  location: LocationRolesDropdown.getLocationId()
    ? [LocationRolesDropdown.getLocationId()]
    : [],
  pos: PlaceOfServiceRolesDropdown.getPlaceOfServiceId()
    ? [PlaceOfServiceRolesDropdown.getPlaceOfServiceId()]
    : [],
  dosStart: getDateString(),
  dosEnd: getDateString(),
  dob: '',
  period: 'TD',
  radiology: [],
  orderStatus: 'A',
  additionalPatientIdentifiersFields: null,
  eligibility: '',
  clinicianGroupId: [],
};

const formatServiceDatePayload = (
  order: TSignOrderItem
): ScheduleServiceDateFormModel => ({
  refid: order.refid,
  date: dateToLocalTimezone({
    date: order.sheduleservdate,
    dateOnly: true,
  }),
  timeStart: order.schedulestime?.match(/^\d{2}:\d{2}/)?.[0] || '',
  timeEnd: order.scheduleendtime?.match(/^\d{2}:\d{2}/)?.[0] || '',
});

export interface PAbstractOrderOverview extends RouteComponentProps {
  data: { refid: number };
  isDivisionEnabled: boolean;
  isFilterVisible: boolean;
  setAdditionalButtons: (children: React.ReactChild) => void;
  toggleFilterVisibility: () => void;
  toggleTabsVisibility?: (flag: boolean) => void;
  isDosFilterAdvanced?: boolean;
}

export class TFilter {
  source: string = 'All';
  isSigned: string = '';
  faceSheet: string = '';
  firstname: string = '';
  lastname: string = '';
  examType: number[] = [];
  facility: number[] = FacilityRolesDropdown.getFacilityId()
    ? [FacilityRolesDropdown.getFacilityId()]
    : [];
  technologist: string = '';
  state: number[] = StateRolesDropdown.getStateId()
    ? [StateRolesDropdown.getStateId()]
    : [];
  location: number[] = LocationRolesDropdown.getLocationId()
    ? [LocationRolesDropdown.getLocationId()]
    : [];
  division: number = 0;
  pos: number[] = PlaceOfServiceRolesDropdown.getPlaceOfServiceId()
    ? [PlaceOfServiceRolesDropdown.getPlaceOfServiceId()]
    : [];
  dosStart: string = getDateString();
  dosEnd: string = getDateString();
  dob: string = '';
  period: string = 'TD';
  radiology: number[] = [];
  orderStatus: string = 'A';
  additionalPatientIdentifiersFields: PatientIdOptions | null = null;
  eligibility: 'I' | 'C' | 'ONLY_SAVE' | 'P' | string = '';
  clinicianGroupId: number[] = [];
}

export class SAbstractOrderOverview {
  onAjax: boolean = false;
  // showFilter: boolean = false;
  isAssignOpen: boolean = false;
  dataSource: Array<TSignOrderItem> = [];
  dataSourceCount: number = 0;
  selected: Array<any> = [];
  pagination: TPagination = new TPagination();
  filter: TFilter = new TFilter();
  showSignModal: boolean = false;
  showDeleteItemPopUp: boolean = false;
  selectedOrder: TSignOrderItem = null;
  showAddRadilogyReport: boolean = false;
  showConfirmAffiliation: boolean = false;
  showVisitNotes: boolean = false;
  patient_comment: string = '';
  showCptDescription: boolean = false;
  showVisitInformation: boolean = false;
  visitInfoDefaultTab: number = 0;
  showRevertCancelled: boolean = false;
  eUnityLink: any = null;
  showDiagnosis: boolean = false;
  showFax: boolean = false;
  showPhysicianAssignment: boolean = false;
  diagnosis: string = '';
  isAuditLogOpen: boolean = false;
  flagOrderEntryPayerInfo: boolean = false;
  showRevert: boolean = false;
  showReportPopup: boolean = false;
  reportPopup: any = {};
  dosEditable: boolean = false;
  scheduledServiceDate: ScheduleServiceDateFormModel | null = null;
  showFaxPhone: boolean = false;
  faxDetail: FaxReportType | null = null;
  showNotes: number = null;
  itemToDelete: any = null;
  showPatientConfirmPopup: boolean = false;
  additionalPatientIdentifiers: any = null;
  patient: { patient_comment: string } = null;
  faxNumbers: any = null;
  faxFiles: any = null;
  //
  unassignOption: boolean = false;
  displayEditAddendumData: TSignOrderItem = null;
  visitNote?: FormModel = undefined;
  isVisitNotesLoading: boolean = false;
  technicianPreAssignValidationError: Map<string, string | number>[] = [];
}

export default abstract class AbstractOrderOverview<
  P extends PAbstractOrderOverview,
  S extends SAbstractOrderOverview
> extends React.Component<P, S> {
  static getDefaultState(gridId: string): SAbstractOrderOverview {
    const state = {
      ...new SAbstractOrderOverview(),
      pagination: getPagination(storeGrid.getPageSize(gridId)),
    };
    return state;
  }

  abstract getGridId(): string;
  abstract getControlPanel(): React.ReactNode;
  abstract renderOrderStatus(): React.ReactNode;
  abstract getTitle(): React.ReactNode;

  abortController: AbortController | null = null;

  COLUMNS: Array<any> = [];

  isColumnsInit = false;

  constructor(props: P) {
    super(props);

    this.COLUMNS = [
      { name: 'ordersrc', title: 'Source' },
      {
        name: 'assign',
        title: 'Assign',
        className: 'text-center width-50',
        render: (_: any, order: TSignOrderItem) => (
          <a
            href="/"
            id="assign-tech"
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                { selectedOrder: order },
                this.assignTechnologistTo
              );
            }}>
            <b>Assign</b>
          </a>
        ),
      },
      {
        name: 'unreassign',
        title: 'Un/Reassign',
        render: (_: any, order: TSignOrderItem) => (
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              this.setState(
                { selectedOrder: order },
                this.assignTechnologistTo
              );
            }}>
            <b>Un/Reassign</b>
          </a>
        ),
      },
      {
        name: 'eligible_date',
        title: 'Eligibility',
        render: (date: string) =>
          dateToLocalTimezone({
            date,
            defaultValue: date,
          }),
        sorter: sortByDate,
      },
      { name: 'eligibility_status', title: 'Eligibility Status' },
      {
        name: 'last_name',
        title: 'Last Name',
        className: 'text-icon-column',
        render: (lastName: string, row: TSignOrderItem) => (
          <div className="text-icon-wrapper">
            <span className="text-icon-pic">
              {row.patient_alert ? (
                <Tooltip
                  body={row.patient_alert}
                  theme="danger"
                  title="Patient alert">
                  <i className="bi bi-exclamation-triangle-fill text-danger" />
                </Tooltip>
              ) : null}
            </span>
            <span>{lastName}</span>
          </div>
        ),
      },
      { name: 'first_name', title: 'First Name' },
      {
        name: 'group_affiliation',
        title: 'Group Affiliation',
        className: 'text-center width-50',
        render: (value: undefined, data: TSignOrderItem) =>
          data.group_affiliation_name ? (
            <Tooltip body={this.mapGroupAffiliation(data)}>
              <b>YES</b>
            </Tooltip>
          ) : null,
      },
      {
        name: 'dos',
        title: 'DOS',
        className: 'text-nowrap',
        render: (value: string, data: TSignOrderItem) =>
          this.renderDosPopUp(data),
        sorter: sortByDate,
      },
      { name: 'uniqueid', title: 'Unique ID' },
      { name: 'state', title: 'State' },
      { name: 'gender', title: 'Gender' },
      {
        name: 'dostime',
        title: 'Time',
        render: (v: any, d: TSignOrderItem) => this.renderDosTime(d),
      },
      {
        name: 'phoneResults',
        title: 'Phone Result',
        render: (r: any, data: TSignOrderItem) => (
          <div className={`available${data.critical ? ' critical' : ''}`}>
            <IconButton
              title={data.critical ? 'Critical Report Phone' : 'Report Phone'}
              onClick={() => {
                this.showPhoneResult(data);
              }}>
              <i className="bi bi-check-circle" />
            </IconButton>
          </div>
        ),
      },
      { name: 'room', title: 'Room No.' },
      { name: 'reason', title: 'Reason' },
      { name: 'cancelreason', title: 'Reason for Cancel' },
      {
        name: 'faxReport',
        title: 'Fax Report',
        className: 'text-center',
        render: (_: any, order: TSignOrderItem) => (
          <IconButton
            className="text-primary"
            title={order.enableRep ? 'Fax exam report' : 'No Document to Fax'}
            disabled={!order.enableRep}
            onClick={() => {
              storeFax.getFaxReport({ order, flag: '' });
            }}>
            <i className="bi bi-printer" />
          </IconButton>
        ),
      },
      {
        name: 'addReport',
        title: 'Add Report',
        className: 'text-center',
        render: (r: any, selectedOrder: TSignOrderItem) => (
          <IconButton
            title="Add report"
            className="text-primary"
            onClick={() => {
              this.setState({
                showAddRadilogyReport: true,
                selectedOrder,
              });
            }}>
            <i className="bi bi-file-earmark-plus" />
          </IconButton>
        ),
      },
      {
        name: 'facility',
        title: 'Facility',
        className: 'text-icon-column',
        render: (phy: any, row: TSignOrderItem) => (
          <div className="text-icon-wrapper">
            <span className="text-icon-pic">
              {row.alert_facility ? (
                <Tooltip
                  body={row.alert_facility}
                  theme="danger"
                  title="Facility alert">
                  <i className="bi bi-exclamation-triangle-fill text-danger" />
                </Tooltip>
              ) : null}
            </span>
            <Tooltip body={this.facilityToolTip(row)} title={row.facilitynm}>
              {row.facilitynm}
            </Tooltip>
          </div>
        ),
        sorter: (v1: any, v2: any, direction: any, i: any, v: any) => {
          const candidate1 = i.facilitynm;
          const candidate2 = v.facilitynm;
          if (candidate1 < candidate2) {
            return -1 * direction;
          } else if (candidate1 > candidate2) {
            return 1 * direction;
          }
          return 0;
        },
      },
      ...(props.isDivisionEnabled
        ? [{ name: 'division', title: 'Division' }]
        : []),
      {
        name: 'revert',
        title: 'Revert',
        render: (v: any, selectedOrder: TSignOrderItem) => (
          <div className="text-primary">
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showRevert: true, selectedOrder });
              }}>
              <i className="bi bi-arrow-counterclockwise" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'revert_cancelled',
        title: 'Revert',
        className: 'text-center',
        render: (v: any, selectedOrder: TSignOrderItem) => (
          <div className="text-primary">
            <IconButton
              onClick={() => {
                this.setState({
                  showRevertCancelled: true,
                  selectedOrder,
                });
              }}>
              <i className="bi bi-arrow-counterclockwise" />
            </IconButton>
          </div>
        ),
      },
      {
        name: 'faxReq',
        title: 'Fax Req',
        render: (r: any, data: TSignOrderItem) => (
          <IconButton
            className="text-primary"
            onClick={() => {
              this.onFaxRequest(data);
            }}>
            <i className="bi bi-printer" />
          </IconButton>
        ),
      },
      {
        name: 'faxed_time',
        title: 'Report Faxed Time',
        render: (date: string) => dateToLocalTimezone({ date }),
      },
      {
        name: 'recipientFaxNumber',
        title: 'Fax #',
      },
      {
        name: 'priority',
        title: 'Order Priority',
        render: (priority: any, row: TSignOrderItem) => {
          const out = this.priorityRender();
          return out ? out : row.priority_nm;
        },
      },
      {
        name: 'cancel',
        title: 'Cancel',
        className: 'text-center',
        render: (r: any, data: TSignOrderItem) => (
          <IconButton
            className="text-muted"
            onClick={(e) => {
              e.preventDefault();
              this.cancelOrder(data);
            }}>
            <i className="bi bi-x-circle" />
          </IconButton>
        ),
      },
      {
        name: 'modality',
        title: 'Modality',
        render: (_: any, data: TSignOrderItem) => {
          return data.examtype;
        },
        sorter: (v1: any, v2: any, direction: any, i: any, v: any) => {
          const candidate1 = i.examtype;
          const candidate2 = v.examtype;
          if (candidate1 < candidate2) {
            return -1 * direction;
          } else if (candidate1 > candidate2) {
            return 1 * direction;
          }
          return 0;
        },
      },
      {
        name: 'physicianname',
        title: 'Physician',
        render: (_: any, data: TSignOrderItem) =>
          this.physicianRender(data as any),
      },
      {
        name: 'viewImage',
        title: 'View Images',
        className: 'text-center',
        render: (v: any, data: TSignOrderItem) => (
          <DiagnosticViewingLink orderId={data.refid} />
        ),
      },
      {
        name: 'cpt',
        title: 'CPT',
        render: (_: any, data: TSignOrderItem) =>
          Array.isArray(data.cptCodes) && data.cptCodes.length ? (
            <div className="fs-5 text-primary">
              <Tooltip body={this.mapCpt(data.cptCodes)}>
                <i className="bi bi-upc-scan" />
              </Tooltip>
            </div>
          ) : null,
      },
      {
        name: 'visitInfo',
        title: 'Visit Info',
        className: 'text-center',
        render: (_: any, selectedOrder: TSignOrderItem) => (
          <IconButton
            className="text-primary"
            onClick={() => {
              this.handleDisplayVisitInfo(selectedOrder);
            }}>
            <i className="bi bi-journal-medical" />
          </IconButton>
        ),
      },
      {
        name: 'ins_last_verified',
        title: 'Last Verified Date',
        render: (date: string) => dateToLocalTimezone({ date }),
        sorter: sortByDate,
      },
      {
        name: 'notes',
        title: 'Notes',
        render: (_: any, data: TSignOrderItem) => (
          <IconButton
            className="text-primary"
            onClick={() => {
              this.setState({ showNotes: data.refid });
            }}>
            <i
              className={`bi ${
                data.hasMedicalLog ? 'bi-envelope-check-fill' : 'bi-envelope'
              }`}
            />
          </IconButton>
        ),
      },
      {
        name: 'ordercreated_dt',
        title: 'Ordered Time',
        render: (date: string) => dateToLocalTimezone({ date }),
        sorter: sortByDate,
      },
      {
        name: 'exam_request',
        title: 'Exam Requisition',
        className: 'text-center',
        render: (_: any, data: TSignOrderItem) => (
          <ExamExportLink key={data.refid} orderId={data.refid} />
        ),
      },
      {
        name: 'physician_assignment',
        title: 'Physician Assignment',
        className: 'width-100',
        render: (_: any, data: TSignOrderItem) => (
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              this.onPhysicianAssignment(data);
            }}>
            Assign
          </a>
        ),
      },
      {
        title: 'Accession #',
        name: 'accessionNumbersStr',
        className: 'text-center',
        render: (accession: string) =>
          accession ? this.codeRender(accession) : null,
      },
      { name: 'patientdob', title: 'DOB', className: 'text-nowrap' },
      { name: 'location', title: 'Location' },
      { name: 'ssn', title: 'SSN' },
      {
        name: 'technologistnm',
        title: 'Technologist',
        render: (techName: string, data: TSignOrderItem) =>
          data.techtooltip ? (
            <Tooltip body={this.technologistTooltip(data.techtooltip)}>
              {techName}
            </Tooltip>
          ) : (
            techName || ''
          ),
      },
      {
        name: 'acknowledgement',
        title: 'Acknowledged',
        textAlign: 'center',
        textAlignHeader: 'center',
        render: (_: any, data: TSignOrderItem) => {
          return this.acknowledgementRender(data);
        },
      },
      {
        name: 'orderassigned_date',
        title: 'Assigned Time',
        render: (date: string) => dateToLocalTimezone({ date }),
        sorter: sortByDate,
      },
      {
        name: 'diagnosis',
        className: 'text-center',
        title: 'Diagnosis',
        render: (_: any, data: TSignOrderItem) =>
          data.icddesc ? (
            <div className="fs-5 text-primary">
              <Tooltip
                body={
                  <>
                    <b>Diagnosis:</b> {data.icddesc}
                  </>
                }>
                <i className="bi bi-clipboard-pulse" />
              </Tooltip>
            </div>
          ) : null,
      },
      {
        name: 'report',
        title: 'Report',
        render: (value: any, data: TSignOrderItem) => {
          const isReportAvailable = data.enableRep;

          const cn = `available${isReportAvailable ? '' : ' negative'}${
            data.critical ? ' critical' : ''
          }`;

          return (
            <div className={cn}>
              <IconButton
                title={data.critical ? 'Critical Report' : 'Report'}
                disabled={!isReportAvailable}
                onClick={() => {
                  this.setState({
                    showReportPopup: true,
                    reportPopup: {
                      orderId: data.refid,
                      patientId: data.patientid,
                    },
                  });
                }}>
                <i
                  className={
                    isReportAvailable ? 'bi bi-check-circle' : 'bi bi-x-circle'
                  }
                />
              </IconButton>
            </div>
          );
        },
      },
      {
        name: 'complete',
        title: 'Complete',
        render: (value: any, data: TSignOrderItem) => (
          <a
            href="/"
            title="Complete"
            onClick={(e) => {
              e.preventDefault();
              this.onCompleteOrder([data.refid]);
            }}>
            <b>Complete</b>
          </a>
        ),
      },
      {
        name: 'visit_notes',
        title: 'Visit Notes',
        render: (value: any, data: TSignOrderItem) => {
          const isActive = data.visitimgsource === 'images/tech_note_g1.png';
          const showVisitNotes = !data.group_affiliation_name;

          return (
            <IconButton
              onClick={() => {
                this.setState(
                  {
                    showVisitNotes,
                    showConfirmAffiliation: Boolean(
                      data.group_affiliation_name
                    ),
                    selectedOrder: data,
                    isVisitNotesLoading: showVisitNotes,
                  },
                  showVisitNotes ? this.getVisitNote : undefined
                );
              }}>
              <i
                className={
                  isActive
                    ? 'icon icon-visit-notes-active'
                    : 'icon icon-visit-notes-inactive'
                }
              />
            </IconButton>
          );
        },
      },
      {
        name: 'faxed',
        title: 'Faxed',
        render: (value: any, order: TSignOrderItem) => {
          const faxedIsEnabled = order.enableRep;

          const isFaxedAvailable = order.enableFax;

          const cn = `available${isFaxedAvailable ? '' : ' negative'}`;

          return (
            <div className={cn}>
              <IconButton
                disabled={!faxedIsEnabled}
                onClick={() => storeFax.getFaxReport({ order, flag: 'OF' })}>
                <i
                  className={
                    isFaxedAvailable ? 'bi bi-check-circle' : 'bi bi-x-circle'
                  }
                />
              </IconButton>
            </div>
          );
        },
      },
      {
        name: 'phoned',
        title: 'Phoned',
        render: (value: any, order: TSignOrderItem) => {
          const isPhonedEnabled = order.enablePh;

          return (
            <IconButton
              className={isPhonedEnabled ? 'text-success' : 'text-secondary'}
              onClick={() => {
                storeFax.getPhoneReport({
                  order,
                  flag: 'PR',
                  isSubscriberEditable: false,
                });
              }}>
              <i
                className={
                  isPhonedEnabled ? 'bi bi-check-circle' : 'bi bi-x-circle'
                }
              />
            </IconButton>
          );
        },
      },
      {
        name: 'auditLog',
        title: 'Auditlog',
        className: 'text-center',
        render: (r: any, data: TSignOrderItem) => (
          <IconButton
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              this.showAuditLog(data);
            }}>
            <i className="icon icon-auditlog" />
          </IconButton>
        ),
      },
      {
        name: 'documents',
        title: 'Documents',
        render: (r: any, order: TSignOrderItem) => {
          const documentIsEnabled = order.enableEx;

          const cn = `available ${documentIsEnabled ? '' : ' negative'}`;

          const selectedOrder = {
            ...order,
            dos: dateToLocalTimezone({ date: order.dos, dateOnly: true }),
          };
          return (
            <div className={cn}>
              <IconButton
                onClick={() => {
                  this.setState({
                    showVisitInformation: true,
                    selectedOrder,
                    visitInfoDefaultTab: 4,
                  });
                }}
                disabled={!documentIsEnabled}>
                <i
                  className={
                    documentIsEnabled ? 'bi bi-check-circle' : 'bi bi-x-circle'
                  }
                />
              </IconButton>
            </div>
          );
        },
      },
      {
        name: 'addendum',
        className: 'text-center',
        title: 'Addendum',
        render: (addendum: any, order: TSignOrderItem) => {
          const displayEditAddendumData = {
            ...order,
            dos: dateToLocalTimezone({
              date: order.sheduleservdate,
              dateOnly: true,
            }),
          };
          return (
            <IconButton
              className="text-primary"
              onClick={() => {
                this.setState({ displayEditAddendumData });
              }}>
              <i className="bi bi-pencil" />
            </IconButton>
          );
        },
      },
      {
        name: 'visit',
        title: 'Visit Schedule',
        className: 'width-150',
        render: (name: any, data: TSignOrderItem) =>
          data.visitindex && data.visitindex !== '0' ? data.visitindex : '',
        sorter: (i: any, v: any, direction: 1 | -1, d1: any, d2: any) => {
          i = d1.visitindex;
          v = d2.visitindex;
          i = i ? i.toLowerCase() : '';
          v = v ? v.toLowerCase() : '';
          if (i > v) {
            return direction;
          }
          if (i < v) {
            return -direction;
          }
          return 0;
        },
      },
      {
        name: 'noofpatients',
        title: 'Patient Seen In Visit',
      },

      {
        name: 'sign',
        title: 'Sign',
        render: (r: any, data: TSignOrderItem) => (
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              if (UserProfileStore.getUserType() === 'D') {
                this.setState({
                  showSignModal: true,
                  itemToDelete: data.refid,
                });
              } else {
                Notification.success(
                  'Sorry! You are not having permission to sign orders'
                );
              }
            }}>
            Sign
          </a>
        ),
      },
      {
        name: 'vw_patientid',
        title: 'Patient ID',
      },
      { name: 'clientMRN', title: 'Other MRN' },
      { name: 'pos', title: 'POS' },
    ];

    const columnsAccessMapping: any = {
      phoned: PERMISSIONS_ORDER_ACTIONS.ADD_PHONE,
      cancel: PERMISSIONS_ORDER_ACTIONS.ORDER_CANCEL,
      //'addReport': PERMISSIONS_ORDER_ACTIONS.ADD_REPORT
    };

    this.COLUMNS = AccessUtils.filter(
      this.COLUMNS,
      columnsAccessMapping,
      (column: { name: string }) => column.name
    );

    const allowed = this.getAllowedColumns();
    if (allowed !== null) {
      const length = this.COLUMNS.length;
      const order: any = {};
      let i;
      let max = 0;
      for (i = length - 1; i >= 0; i--) {
        const index = allowed.indexOf(this.COLUMNS[i].name);
        if (index !== -1) {
          if (index > max) {
            max = index;
          }
          order[index] = this.COLUMNS[i];
        }
      }
      i = 0;
      this.COLUMNS = [];
      while (i <= max) {
        const col = order[i++];
        if (col) {
          this.COLUMNS.push(col);
        }
      }
    }
  }

  getAllowedColumns(): Array<string> {
    return [];
  }

  setAdditionalControls(): void {}

  handleDoubleClick = (order: TSignOrderItem) => {
    this.handleDisplayVisitInfo(order);
  };

  handleDisplayVisitInfo = (
    order: TSignOrderItem,
    visitInfoDefaultTab: number = 0
  ) => {
    if (this.props.toggleTabsVisibility) this.props.toggleTabsVisibility(false);

    const dos = dateToLocalTimezone({
      date: order.dos,
      dateOnly: true,
    });

    const ordercreated_dt = dateToLocalTimezone({
      date: order.ordercreated_dt,
    });

    const orderassigned_date = dateToLocalTimezone({
      date: order.orderassigned_date,
    });

    const ordercompletion_date = dateToLocalTimezone({
      date: order.ordercompletion_date,
    });

    const sheduleservdate = dateToLocalTimezone({
      date: order.sheduleservdate,
      dateOnly: true,
    });

    const faxed_time = dateToLocalTimezone({ date: order.faxed_time });

    const selectedOrder = {
      ...order,
      dos,
      sheduleservdate,
      ordercreated_dt,
      orderassigned_date,
      ordercompletion_date,
      faxed_time,
    };

    this.setState({
      selectedOrder,
      visitInfoDefaultTab,
      showVisitInformation: true,
    });
  };

  renderDosPopUp(data: TSignOrderItem) {
    const dos = dateToLocalTimezone({
      date: data.sheduleservdate,
      dateOnly: true,
    });

    return this.state.dosEditable ? (
      <a href="/" onClick={(e) => this.showDosPopUp(data, e)}>
        {dos}
      </a>
    ) : (
      dos
    );
  }

  renderDosTime({ schedulestime, scheduleendtime }: TSignOrderItem) {
    const timeStart = schedulestime?.match(/^\d{2}:\d{2}/)?.[0] || '';

    const timeEnd = scheduleendtime?.match(/^\d{2}:\d{2}/)?.[0] || '';

    if (!timeStart && !timeEnd) {
      return '';
    }

    return timeStart && timeEnd ? (
      <div>
        {timeStart} - {timeEnd}
        <SupOneDay start={timeStart} end={timeEnd} />
      </div>
    ) : (
      timeStart
    );
  }

  showDosPopUp(data: TSignOrderItem, event: any) {
    if (event) {
      event.preventDefault();
    }
    const scheduledServiceDate = formatServiceDatePayload(data);
    this.setState({ scheduledServiceDate });
  }

  onCompleteOrder(orderIdList: Array<number>) {
    const selected = [...orderIdList];
    if (!selected || selected.length === 0) {
      Notification.warning('Select at least one order!');
      return;
    }
    OrderOverviewActions.completeOrders(selected).then((response) => {
      if (response === 'S') {
        Notification.success('Successfully completed!');
        this.updateData();
      } else {
        Notification.danger('An error occurred!');
      }
    });
  }

  getStartShownColumns() {
    if (this.isColumnsInit === true) {
      return null;
    }
    this.isColumnsInit = true;
    const shownColumns = this.getShownColumns();
    if (shownColumns === null) {
      return null;
    }
    const out: any = {};
    this.getColumns().forEach((column: any, index: number) => {
      if (shownColumns.indexOf(column.name) === -1) {
        out[column.name] = false;
      }
    });
    return out;
  }

  getShownColumns(): Array<string> {
    return null;
  }

  onAssignOrder() {
    const { selected } = this.state;

    const orders = selected.map(this.findData);

    const errors = validateBeforeAssignment(orders);

    this.setState({
      technicianPreAssignValidationError: errors,
      isAssignOpen: !errors.length,
    });
  }

  async onFaxRequest(data: TSignOrderItem) {
    this.setState({
      onAjax: true,
    });

    try {
      const promiseReport = exportExam(Number(data.refid));

      const promiseFaxes = OrderFormPageActions.getFaxPhone(data.refid).then(
        (faxPhone) => {
          const faxPhoneArr: Array<any> = Array.isArray(faxPhone)
            ? faxPhone
            : [{ Fax: '' }];

          return faxPhoneArr.map((fax) => ({
            receiverName: data.facilitynm || '',
            faxNumber: fax.Fax || '',
          }));
        }
      );

      // @ts-ignore
      const [{ value: filePath }, { value: faxes }] = await Promise.allSettled([
        promiseReport,
        promiseFaxes,
      ]);

      const fileName = `${data.first_name} ${data.last_name}.pdf`;

      const files = [
        {
          fileName,
          filePath,
          isChecked: true,
          isCritical: false,
        },
      ];

      const faxDetail: FaxReportType = {
        defaultValues: {
          files,
          faxes,
          flag: 'OC',
        },
        id: Number(data.refid),
        dos: dateToLocalTimezone({ date: data.dos, dateOnly: true }),
        facility: data.facilitynm,
        patient: data.patientnm,
        examType: data.examtype,
        room: data.room,
      };

      this.setState({ faxDetail, onAjax: false });
    } catch (e: any) {
      this.setState({ faxDetail: null, onAjax: false });
    }
  }

  handleSendFaxRequisition = () => {
    this.setState({ faxDetail: null });
    this.updateData();
  };

  handleCloseFaxReport = () => {
    this.setState({ faxDetail: null });
  };

  physicianRender(
    data: TSignOrderItem & { physicianname: string; phy_flag: 'R' | 'I' | '' }
  ) {
    let content;
    if (UserProfileStore.isClientUser()) {
      content = data.physicianname;
    } else {
      if (data.phy_flag === 'R') {
        content = (
          <span>
            {data.physicianname} <span className="text-danger">(REMOVED)</span>
          </span>
        );
      } else if (data.phy_flag === 'I') {
        content = (
          <span>
            {data.physicianname} <span className="text-danger">(Inactive)</span>
          </span>
        );
      } else {
        content = data.physicianname;
      }
    }
    return <Tooltip body={this.physicianToolTip(data)}>{content}</Tooltip>;
  }

  mapGroupAffiliation(data: {
    refid: number;
    group_affiliation_name: string;
    group_affiliation_description: string;
  }) {
    return (
      <div className="fw-bold">
        <p>{data.group_affiliation_name}</p>
        <p>{data.group_affiliation_description || ''}</p>
      </div>
    );
  }

  mapCpt(cptCodes: TSignOrderItem['cptCodes']) {
    const codesList = cptCodes.map((code, i) => (
      <p key={'map_cpt_' + i}>
        {1 + i}) <b>{code.cpt_code}</b> - {code.cpt_desc} ({code.session_no})
      </p>
    ));

    return codesList;
  }

  technologistTooltip(str: string) {
    const data: Array<React.ReactNode> = str.split('\n');
    const length = data.length;
    for (let i = length - 1; i > 0; i--) {
      data.splice(i, 0, <br key={'tt_' + i} />);
    }
    return data;
  }

  facilityToolTip(data: TSignOrderItem): React.ReactNode {
    return (
      <>
        <p>Order Source: {data.order_source}</p>
        <p>Caller Name: {data.order_source_nm}</p>
        <p>Direct Number: {data.order_based}</p>
        <p>Address: {`${data.address1} ${data.address2}`}</p>
        <p>City: {data.city}</p>
        <p>State: {data.state}</p>
        <p>Zip Code: {data.zipcode}</p>
        <p>Phone: {data.phone}</p>
        <p>Email: {data.bill_email}</p>
        <p>NPI: {data.facility_npi}</p>
      </>
    );
  }

  physicianToolTip = (data: TSignOrderItem) => (
    <>
      <p>Address: {data.phyaddress}</p>
      <p>City: {data.phycity}</p>
      <p>State:{data.phystate}</p>
      <p>Zip Code: {data.phyzip}</p>
      <p>Phone: {data.phyphone}</p>
      <p>Email: {data.phyemail}</p>
      <p>NPI: {data.phynpi}</p>
    </>
  );
  acknowledgementRender(data: TSignOrderItem) {
    const acknowledgement = data.acknowledgement === 'Y' ? 'N' : 'Y';
    return (
      <input
        type="button"
        className="text-primary"
        onClick={() => {
          this.setState({ onAjax: true }, () => {
            storeAssignedOrders
              .updateAcknowledgement(data.refid, acknowledgement)
              .then((status) => {
                if (status) {
                  data.acknowledgement = acknowledgement;
                }
                this.setState({ onAjax: false });
              });
          });
        }}
        style={{
          backgroundColor: 'transparent',
          borderWidth: 0,
          fontWeight: data.acknowledgement === 'Y' ? 'bold' : undefined,
        }}
        value={data.acknowledgement === 'N' ? 'NO' : 'YES'}
      />
    );
  }

  onVisitSchedule(name: any, value: any, row: { refid: number; dos: string }) {
    VisitScheduleActions.addPatientVisit(row.refid, row.dos, value).then(
      (response) => {
        this.updateData();
      }
    );
  }

  componentDidMount() {
    this.updateData();
    this.setAdditionalControls();
  }

  componentWillUnmount() {
    OrderOverviewActions.clearDataSource();
    storeGrid.saveFilter(this.getGridId(), this.state.filter);
    if (this.abortController) {
      this.abortController.abort();
    }
    this.props.setAdditionalButtons(null);
  }

  getColumns() {
    return this.COLUMNS;
  }

  handleCloseTechnicianPreAssignValidationDialog = () => {
    this.setState({ technicianPreAssignValidationError: [] });
  };

  handleRedirectToVisitInfo = (id: number) => {
    const order = this.findData(id);

    this.handleCloseTechnicianPreAssignValidationDialog();
    this.handleDisplayVisitInfo(order, 1);
  };

  assignTechnologistTo = () => {
    const errors = validateBeforeAssignment([this.state.selectedOrder]);

    this.setState({
      technicianPreAssignValidationError: errors,
      isAssignOpen: !errors.length,
    });
  };

  onPhysicianAssignment(data: any) {
    const selectedOrder = {
      ...data,
      assign_providerid: Number(data.assign_providerid) || 0,
      assign_providergrpid: Number(data.assign_providergrpid) || 0,
    };
    this.setState({ showPhysicianAssignment: true, selectedOrder });
  }

  handleCloseDialogAssign = () => {
    this.setState({ isAssignOpen: false, selectedOrder: null });
  };

  handleApproveAssign = () => {
    this.handleCloseDialogAssign();
    this.updateData();
  };

  priorityRender(): React.ReactNode {
    return null;
  }

  cancelOrder(data: TSignOrderItem) {
    this.setState({ showDeleteItemPopUp: true, selectedOrder: data });
  }

  showAuditLog(order: TSignOrderItem) {
    this.setState({ selectedOrder: order, isAuditLogOpen: true });
  }

  showPhoneResult(data: TSignOrderItem) {
    storeFax.getPhoneReport({
      order: data,
      flag: 'PR',
      isSubscriberEditable: true,
    });
  }

  handleCloseDeleteConfirmation = () => {
    this.setState({ showDeleteItemPopUp: false, selectedOrder: null });
  };

  handleDeleteConfirmationApprove = () => {
    this.handleCloseDeleteConfirmation();
    this.updateData();
  };

  onUpdateSelection = (selected: Array<any>) => {
    this.setState({ selected }, this.setAdditionalControls);
  };

  onFilterSubmit() {
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };
    this.setState({ pagination }, () => this.updateData(false));
  }

  onReset(filter: TFilter = new TFilter()) {
    const pagination = {
      ...this.state.pagination,
      page: 1,
      skip: 0,
    };
    this.setState(
      { filter, pagination },
      this.updateData ? () => this.updateData() : null
    );
  }

  handleCloseDialogNotes = (shouldUpdate: boolean) => {
    this.setState({ showNotes: null });

    if (shouldUpdate) {
      this.updateData();
    }
  };

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination, onAjax: true }, () => this.updateData(false));
  };

  getPageAppId() {
    return '';
  }

  getVisitNote = () => {
    const order = this.getVisitNotesOrder();
    if (order?.refid) {
      VisitNotesActions.getVisitNotesForOrder(order).then((response) => {
        this.setState({
          visitNote: response,
          isVisitNotesLoading: false,
        });
      });
    } else {
      this.setState({ isVisitNotesLoading: false });
    }
  };

  handleCloseVisitInfo = () => {
    if (this.props.toggleTabsVisibility) this.props.toggleTabsVisibility(true);
    this.setState({
      showVisitInformation: false,
      selectedOrder: null,
      visitInfoDefaultTab: 0,
    });
  };

  handleAssignPhysician = () => {
    this.setState({ showPhysicianAssignment: false }, () => this.updateData());
  };

  handleCloseDialogPhysicianAssignment = () => {
    this.setState({ showPhysicianAssignment: false });
  };

  handleClearReportPhone = () => {
    storeFax.clearReportPhone();
    this.updateData();
  };

  handleCloseAddendum = () => {
    this.setState({ displayEditAddendumData: null });
  };

  handleUpdateAddendum = () => {
    this.setState({ displayEditAddendumData: null }, this.updateData);
  };

  handleSendFaxReport = () => {
    this.updateData();
    storeFax.clearReport();
  };

  handleClickReset = () => {
    // @ts-ignore
    const defaultFilter = this.getDefaultFilter
      ? // @ts-ignore
        this.getDefaultFilter()
      : undefined;

    this.onReset(defaultFilter);
  };

  render() {
    const controlPanel = this.getControlPanel();

    const { state } = this;

    const {
      showVisitInformation,
      selectedOrder,
      visitNote,
      isVisitNotesLoading,
    } = state;
    const visitNotesOrder = this.getVisitNotesOrder();

    return (
      <div>
        {showVisitInformation ? (
          <VisitInformationPage
            selectedOrder={selectedOrder}
            defaultTab={state.visitInfoDefaultTab}
            hideTabs={this.handleCloseVisitInfo}
            callback={() => {
              this.setState({
                showVisitInformation: false,
              });
              this.updateData();
            }}
          />
        ) : null}
        <div className={showVisitInformation ? 'visually-hidden' : ''}>
          {this.getContentBefore()}
          {this.getFilterComponent()}
          {this.renderOrderStatus ? this.renderOrderStatus() : null}
          <Observer>
            {() => storeFax.fetchingReport && <SpinnerFixed />}
          </Observer>
          <Grid
            ref="grid"
            selectId="refid"
            minColumns={5}
            gridControlPanel={controlPanel}
            onAjax={state.onAjax}
            id={this.getGridId()}
            columns={this.getColumns()}
            dataSource={state.dataSource}
            pagination={state.pagination}
            rowArguments={this.buildColors}
            dataSourceCount={state.dataSourceCount}
            shownColumns={this.getStartShownColumns()}
            onSelectChange={this.onUpdateSelection}
            onPaginationChange={this.onPaginationChange}
            onDoubleClick={this.handleDoubleClick}
          />
          {state.displayEditAddendumData ? (
            <DialogAddendum
              order={state.displayEditAddendumData}
              onClose={this.handleCloseAddendum}
              onUpdate={this.handleUpdateAddendum}
            />
          ) : null}
          {state.showDeleteItemPopUp && (
            <DialogCancelOrder
              id={selectedOrder.refid}
              onClose={this.handleCloseDeleteConfirmation}
              onApproveCancel={this.handleDeleteConfirmationApprove}
            />
          )}

          {state.isAssignOpen && (
            <DialogAssignTechnician
              unassignOption={state.unassignOption}
              orderIds={selectedOrder ? [selectedOrder.refid] : state.selected}
              isVaccinationRequired={
                selectedOrder
                  ? selectedOrder.isVaccinationRequired
                  : checkVaccinationRequiredFlag(
                      state.dataSource,
                      state.selected
                    )
              }
              orderList={
                selectedOrder
                  ? [selectedOrder]
                  : this.getOrdersByIds(state.selected)
              }
              onClose={this.handleCloseDialogAssign}
              onApprove={this.handleApproveAssign}
            />
          )}
          {state.showConfirmAffiliation && (
            <Dialog handleClose={this.handleCloseDialogConfirmAffiliation}>
              <DialogHeader title="Affiliation" />
              <DialogBody>
                <div
                  style={{
                    minHeight: 100,
                  }}>
                  <i className="bi bi-exclamation-triangle-fill text-danger me-3" />
                  {visitNotesOrder?.group_affiliation_name || ''}
                  <br />
                  <br />
                  {visitNotesOrder?.group_affiliation_description || ''}
                </div>
              </DialogBody>
              <DialogFooter>
                <Button
                  text="Cancel"
                  variant="warning"
                  onClick={this.handleCloseDialogConfirmAffiliation}
                />
                <Button
                  text="Confirm"
                  onClick={this.handleApproveAffiliation}
                />
              </DialogFooter>
            </Dialog>
          )}
          {state.showVisitNotes && (
            <DialogVisitNotes
              defaultValues={visitNote}
              order={visitNotesOrder}
              fetching={isVisitNotesLoading}
              onClose={this.handleCloseVisitNotes}
              onSubmit={this.handleSaveVisitNotes}
            />
          )}
          {state.showAddRadilogyReport
            ? this.getRadReport(selectedOrder.refid)
            : null}

          {state.faxDetail && (
            <DialogFax
              title="Fax Requisition"
              details={state.faxDetail}
              onSendFax={this.handleSendFaxRequisition}
              onClose={this.handleCloseFaxReport}
            />
          )}

          {state.scheduledServiceDate && (
            <DialogScheduledServiceDate
              defaultValues={state.scheduledServiceDate}
              onClose={this.handleCloseDOSDialog}
              onSubmit={this.handleUpdateDOSDialog}
            />
          )}
          {state.showNotes ? (
            <OrderMessageDialog
              onClose={this.handleCloseDialogNotes}
              id={state.showNotes}
            />
          ) : null}
          <Observer>
            {() =>
              storeFax.report && (
                <DialogFax
                  details={storeFax.report}
                  onSendFax={this.handleSendFaxReport}
                  onClose={storeFax.clearReport}
                />
              )
            }
          </Observer>
          <Observer>
            {() =>
              storeFax.reportPhone && (
                <DialogPhone
                  title={
                    storeFax.reportPhone.isSubscriberEditable
                      ? 'Phone Confirmation'
                      : 'Phone Report'
                  }
                  details={storeFax.reportPhone}
                  onSendFax={this.handleClearReportPhone}
                  onClose={storeFax.clearReportPhone}
                />
              )
            }
          </Observer>

          {state.showPhysicianAssignment && (
            <DialogPhysicianAssignment
              orderId={selectedOrder.refid}
              // @ts-ignore
              order={selectedOrder}
              onAssign={this.handleAssignPhysician}
              onClose={this.handleCloseDialogPhysicianAssignment}
            />
          )}
          {state.isAuditLogOpen && (
            <DialogAuditLog
              order={selectedOrder}
              onClose={() => this.setState({ isAuditLogOpen: false })}
            />
          )}
          {state.showRevert && (
            <DialogConfirm
              onCancel={this.handleCloseRevertOrder}
              onApprove={this.handleApproveRevertOrder}>
              Do you want to revert verification check for this order?
            </DialogConfirm>
          )}
          {state.showRevertCancelled && (
            <DialogConfirm
              onCancel={this.handleCloseDialogRevert}
              onApprove={this.handleApproveRevert}>
              Do you want to revert this order?
            </DialogConfirm>
          )}
          {state.showSignModal && (
            <DialogConfirm
              onCancel={this.handleCloseDialogSignOrder}
              onApprove={this.handleApproveOrderSign}>
              Do you want to sign for this order?
            </DialogConfirm>
          )}
          {state.technicianPreAssignValidationError.length ? (
            <TechnicianPreAssignValidationDialog
              errors={state.technicianPreAssignValidationError}
              onMessageClick={this.handleRedirectToVisitInfo}
              onClose={this.handleCloseTechnicianPreAssignValidationDialog}
            />
          ) : null}
          {state.showPatientConfirmPopup && (
            <DialogConfirm
              onCancel={() => {
                this.setState({ showPatientConfirmPopup: false });
              }}
              onApprove={() =>
                this.setState(
                  { showPatientConfirmPopup: false },
                  this.assignTechnologistTo
                )
              }>
              {state.patient.patient_comment} <br /> Are you sure you want to
              continue?
            </DialogConfirm>
          )}

          {state.additionalPatientIdentifiers ? (
            <DialogAdditionalPatientIds
              defaultValues={state.filter.additionalPatientIdentifiersFields}
              onSubmit={this.additionalPatientIdentifierCallback}
              onClose={this.handleCloseDialogAdditionalPatientIds}
            />
          ) : null}
          {state.showReportPopup && (
            <DialogTranscriptionReports
              onClose={() =>
                this.setState({
                  showReportPopup: false,
                  reportPopup: {},
                })
              }
              patientId={state.reportPopup.patientId}
              orderId={state.reportPopup.orderId}
            />
          )}
        </div>
      </div>
    );
  }

  handleCloseDOSDialog = () => {
    this.setState({ scheduledServiceDate: null });
  };

  handleUpdateDOSDialog = () => {
    this.handleCloseDOSDialog();
    this.updateData();
  };

  additionalPatientIdentifierCallback(data: PatientIdOptions) {}

  handleCloseDialogAdditionalPatientIds() {}

  handleCloseDialogRevert = () => {
    this.setState({ showRevertCancelled: false });
  };

  handleApproveRevert = () => {
    this.handleCloseDialogRevert();

    const { selectedOrder } = this.state;

    OrderOverviewActions.revertCancelledOrder(selectedOrder).then(() => {
      Notification.success('Order was reverted');
      this.updateData(false);
    });
  };

  getVisitNotesOrder() {
    const { state } = this;
    if (state.selectedOrder) {
      return state.selectedOrder;
    } else if (state.selected && state.selected.length === 1) {
      const selectedOrder = this.findData(state.selected[0]);
      return selectedOrder;
    }
    return null;
  }
  handleCloseTranscription = (shouldUpdate: boolean) => {
    this.setState(
      { showAddRadilogyReport: false },
      shouldUpdate ? () => this.updateData() : undefined
    );
  };

  getRadReport(orderId: number) {
    const order = this.findData(orderId);
    const orderDetails = {
      ...order,
      dos: dateToLocalTimezone({
        date: order.sheduleservdate,
        dateOnly: true,
      }),
    };
    const patientInfo = {
      patientName: orderDetails.patientnm,
      dob: orderDetails.patientdob,
      dos: orderDetails.dos,
      gender: orderDetails.gender as 'M' | 'F' | '',
      examType: orderDetails.examtype,
      id: Number(orderDetails.patientid),
    };

    return (
      <DialogTranscription
        orderId={orderDetails.refid}
        patientInfo={patientInfo}
        onClose={this.handleCloseTranscription}
      />
    );
  }

  getContentBefore(): React.ReactNode {
    return null;
  }

  findOrderKey(
    order: TSignOrderItem & {
      StudyId?: number;
      studyId?: number;
      studyID?: number;
      studyid?: number;
    }
  ) {
    if (!order) {
      return null;
    }
    if (order.refid) {
      return 'refid';
    } else if (order.StudyId) {
      return 'StudyId';
    } else if (order.studyId) {
      return 'studyId';
    } else if (order.studyID) {
      return 'studyID';
    } else if (order.studyid) {
      return 'studyid';
    }
    throw new Error('No refid key');
  }

  getOrdersByIds(orderIds: number[]) {
    const orders: Array<any> = orderIds.map((id) =>
      this.state.dataSource.find(({ refid }) => refid === id)
    );
    return orders;
  }

  handleCloseDialogConfirmAffiliation = () => {
    this.setState({
      showVisitNotes: false,
      showConfirmAffiliation: false,
    });
  };

  handleApproveAffiliation = () => {
    this.setState(
      {
        showVisitNotes: true,
        showConfirmAffiliation: false,
      },
      this.getVisitNote
    );
  };

  handleCloseRevertOrder = () => {
    this.setState({ showRevert: false });
  };

  handleApproveRevertOrder = () => {
    this.handleCloseRevertOrder();
    EligibilityManagerPageActions.revertOrder(
      this.state.selectedOrder.refid
    ).then(() => {
      Notification.success('Order successfully reverted to waiting status');
      this.updateData();
    });
  };

  closeEligibility(v: any) {
    const newState: SAbstractOrderOverview = Object.assign({
      showEligibilityPopup: false,
      selectedOrder: null,
    });
    if (v) {
      this.setState(newState, () => this.updateData());
    } else {
      this.setState(newState);
    }
  }

  handleCloseDialogSignOrder = () => {
    this.setState({
      showSignModal: false,
      itemToDelete: null,
    });
  };

  handleApproveOrderSign = () => {
    this.setState({ onAjax: true });
    SignOrdersPageActions.confirmSign(
      this.state.itemToDelete,
      UserProfileStore.getUserId(),
      UserProfileStore.getUserType()
    ).then((response: any) => {
      if (response === 'S') {
        Notification.success('Order was Signed!');
        this.updateData();
      } else {
        Notification.danger('An error occurred!');
      }
    });

    this.handleCloseDialogSignOrder();
  };

  buildColors = (v?: { row_color: string }) => {
    const color = v?.row_color;

    const backgroundColor = color
      ? color.startsWith('#')
        ? color
        : `#${color}`
      : '#fff';

    return {
      style: { backgroundColor, borderTop: '1px solid #c2c2c2' },
    };
  };

  handleCloseVisitNotes = () => {
    this.setState({
      showVisitNotes: false,
      selectedOrder: null,
      visitNote: undefined,
    });
  };

  handleSaveVisitNotes = () => {
    this.handleCloseVisitNotes();
    this.updateData();
  };

  findData = (id: number): TSignOrderItem => {
    if (!id) {
      return new TSignOrderItem();
    }

    return this.state.dataSource.find(({ refid }) => refid === id);
  };

  getRecordsAccess(): 'M' | 'A' {
    return 'M'; // 'M' or 'A' only
  }

  handleChangeDivision = (division: number) => {
    const filter = {
      ...this.state.filter,
      division,
    };

    this.setState({ filter });
  };

  getFilterComponent() {
    const { isFilterVisible, isDosFilterAdvanced } = this.props;
    const className = 'col-md-6 col-lg-3';
    const radioClass = `${className} part-inline`;
    return (
      <Form
        className={isFilterVisible ? '' : ' d-none'}
        onCollectValues={this.handleChangeFilter}
        model={this.state.filter}
        submit={() => this.onFilterSubmit()}>
        <Text name="lastname" label="Last Name" className={className} />
        <Text name="firstname" label="First Name" className={className} />
        <ExamTypeDropdown
          name="examType"
          label="Modality"
          attr={{ isMulti: true, isStyleDependsOnValue: true }}
          optionsForOrderOnly
          className={className}
        />
        <FacilityRolesDropdown
          name="facility"
          attr={{ isMulti: true, isStyleDependsOnValue: true }}
          className={className}
        />

        {UserProfileStore.getRecordsAccess() === 'M' &&
        this.getRecordsAccess() === 'M' ? (
          <TechnologistDropdown
            name="technologist"
            className={className}
            attr={{ disabled: true }}
            value={UserProfileStore.getTypedUserId()}
          />
        ) : (
          <TechnologistDropdown name="technologist" className={className} />
        )}
        <StateRolesDropdown
          name="state"
          attr={{ isMulti: true, isStyleDependsOnValue: true }}
          className={className}
        />
        <LocationRolesDropdown
          name="location"
          label="Location"
          attr={{ isMulti: true, isStyleDependsOnValue: true }}
          className={className}
        />
        <>
          <DropdownPureDivision
            name="division"
            label="Division"
            className={className}
            onChange={this.handleChangeDivision}
            value={this.state.filter.division}
          />
        </>
        <PlaceOfServiceRolesDropdown
          name="pos"
          label="Place of Service"
          attr={{ isMulti: true, isStyleDependsOnValue: true }}
          className={className}
        />
        <DateRangeLegacy
          nameFrom="dosStart"
          nameTo="dosEnd"
          nameRadio="period"
          onChange={this.handleChangeDatePeriod}
          isDosOptionsAdvanced={Boolean(isDosFilterAdvanced)}
        />
        <TextCalendar
          name="dob"
          label="DOB"
          className="col-sm-6 col-md-4 col-lg-3 col-xl-2"
        />
        <RadiologyDropdown
          name="radiology"
          label="Radiology Group"
          attr={{ isMulti: true, isStyleDependsOnValue: true }}
          className={className}
        />
        <Radio
          name="source"
          label="Order Source"
          options={SOURCE_OPTIONS}
          className={radioClass}
        />
        <Radio
          name="isSigned"
          label="Provider"
          options={SIGNED_OPTIONS}
          className={radioClass}
        />
        <Radio
          name="faceSheet"
          label="Face-sheet attached"
          options={radioOptions.yesNoAll}
          className={radioClass}
        />

        <ClinicianGroupDropdown
          name="clinicianGroupId"
          label="Clinician Group"
          attr={{ isMulti: true, isStyleDependsOnValue: true }}
          className={className}
        />

        {this.getAdditionalFilters()}
        <ControlsLayout alignX="auto">
          <Button
            text="Reset"
            variant="warning"
            onClick={this.handleClickReset}
          />
          <Button text="Apply" type="submit" data-testid="applyFilter" />
        </ControlsLayout>
      </Form>
    );
  }

  getAdditionalFilters(): React.ReactNode {
    return null;
  }

  updateData(resetPagination: boolean = false) {
    const newState = {
      onAjax: true,
      pagination: resetPagination ? getPagination() : this.state.pagination,
    };

    this.setState(newState, () => {
      const controller = new AbortController();

      this.abortController = controller;

      OrderOverviewActions.loadGridData(
        this.prepareFilter() as TOrderOverviewFilter,
        this.state.pagination,
        controller.signal
      ).then(this.updateDataCallback);
    });
  }

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.abortController = null;

    this.setState({
      onAjax: false,
      selected: [],
    });
    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  prepareFilter(): any {
    const { isDivisionEnabled } = this.props;
    const { filter } = this.state;
    const recordsAccess =
      UserProfileStore.getRecordsAccess() === 'M' &&
      this.getRecordsAccess() === 'M'
        ? 'M'
        : 'A';

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      corporateId: 0,
      dosSearch: '',
      srchlastnameval: filter.lastname || '',
      srchdate: filter.dob || '',
      facilityid: filter.facility,
      examtype: filter.examType,
      status: 'N',
      fromdt: dos.dateFrom || '',
      todate: dos.dateTo || '',
      provid: null,
      pcpid: 0,
      currentUserType: UserProfileStore.getUserType(),
      sign: '',
      srchfirstnameval: filter.firstname,
      srchssnval: '',
      regionArr: [],
      faxstatus: '',
      techId: parseInt(
        recordsAccess === 'M'
          ? '' + UserProfileStore.getTypedUserId()
          : '' + filter.technologist !== '' + 0
          ? filter.technologist
          : '' + 0
      ),
      recordsAccess: recordsAccess,
      stateId: filter.state,
      locId: LocationRolesDropdown.canSeeLocations() ? filter.location : [],
      posId: filter.pos,
      radioId: filter.radiology,
      ordSource: filter.source,
      signed: filter.isSigned,
      faceSheet: filter.faceSheet,
      orderStatus: filter.orderStatus || '',
      clinicianGroupId: filter.clinicianGroupId,
      division: isDivisionEnabled ? filter.division : 0,
    };
  }

  handleChangeFilter = (name: string, value: any) => {
    const filter = { ...this.state.filter, [name]: value };
    this.setState({ filter });
  };

  handleChangeDatePeriod = (filter: TFilter) => {
    this.setState({ filter });
  };

  afterFilterChange() {}

  codeRender(accession: string) {
    const formattedData = accession
      .split(',')
      .map((v: string) => v.trim().split(' - '))
      .map((v: any, i: any) => (
        <div key={i}>
          {v.length === 2 ? (
            <span>
              <strong>{v[0]}</strong> - {v[1]}
            </span>
          ) : (
            v[0]
          )}
        </div>
      ));
    return (
      <Tooltip body={formattedData}>
        <i className="bi bi-upc-scan fs-5 text-primary" />
      </Tooltip>
    );
  }
}
