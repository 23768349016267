import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { calcDateRange } from 'utils/calcDateRange';

export const defaultFilterValue: FilterType = {
  lastName: '',
  firstName: '',
  examType: 0,
  facilityId: 0,
  stateId: 0,
  locationId: 0,
  radiologyGroupId: 0,
  radiologistId: 0,
  pos: 0,
  billProcess: '',
  period: 'TD',
  dosStart: calcDateRange('TD').dosStart,
  dosEnd: calcDateRange('TD').dosEnd,
  providerType: 0,
  division: 0,
};

export interface FilterType {
  lastName: string;
  firstName: string;
  examType: number;
  facilityId: number;
  stateId: number;
  locationId: number;
  radiologyGroupId: number;
  radiologistId: number;
  pos: number;
  billProcess: string;
  period: string;
  dosStart: string;
  dosEnd: string;
  providerType: number;
  division: number;
}

export interface LogType {
  Studyid: number;
  bill_process: string;
  birthdate: string;
  claimUniqId: string;
  cpt: string;
  cpt_desc: string;
  cpt_price: string;
  dos: string;
  examtype: string;
  fac_bill_phone: string;
  fac_id: number;
  facilityid: number;
  facilitynm: string;
  icd: string;
  icd_desc: string;
  ins_notes: string;
  insurance_status: string;
  medicaid_id: string;
  medicaid_stateid: number;
  medicare_id: string;
  order_based: string;
  patient_mrn: string;
  patientfist: string;
  patientid: number;
  patientlast: string;
  pos: string;
  practitioner_first: string;
  practitioner_last: string;
  practitioner_npi: string;
  pri_groupno: string;
  prof_guarantor: string;
  profession: null | string;
  radiologist: string;
  radiologistIdsList: number;
  radiologygroupid: number;
  refid: number;
  sec_groupno: string;
  showimage: string;
  ssn: string;
  state_nm: string;
  stateid: number;
  ter_groupno: string;
  vw_patientid: string;
  division: string;
}

export interface InsuranceDetailsType {
  last_name: string;
  first_name: string;
  dob: string;
  ssn: string;
  dos: string;
  iceNumber: string;
  inmateNumber: string;
  immigrationNumber: string;
  state: string;
  facilitynm: string;
  phone: string;
  face_sheet: string;
  medicare_id: string;
  medicaid_id: string;
  medicaid_stateid: number;
  ins_notes: string;
  correctionalFacility: boolean;
  patientid: number;
  refid: number;
  facilityid: number;
}

interface ReviewType {
  facilityId: number;
  patientId: number;
}

class ProFfsReport {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;
  reviewProps: ReviewType | null = null;
  insuranceDetails: InsuranceDetailsType | null = null;

  page: Pagination = new Pagination({ id: 'pro_ffs_reports_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      reviewProps: observable,
      insuranceDetails: observable,
      filter: observable,

      setLogList: action,
      setLogCount: action,
      setFetching: action,
      setFilter: action,
      setForReview: action,
      setInsuranceDetails: action,
      clearReview: action.bound,
      clearInsuranceDetails: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setLogList(list: LogType[]) {
    this.logList = list;
  }

  setLogCount(count: number) {
    this.logTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setForReview(reviewProps: ReviewType) {
    this.reviewProps = reviewProps;
  }

  setInsuranceDetails(data: LogType) {
    const details = {
      last_name: data.patientfist,
      first_name: data.patientlast,
      dob: data.birthdate,
      ssn: data.ssn,
      dos: data.dos,
      iceNumber: '',
      inmateNumber: '',
      immigrationNumber: '',
      state: data.state_nm,
      facilitynm: data.facilitynm,
      facilityid: data.facilityid,
      phone: data.fac_bill_phone,
      face_sheet: '',
      medicare_id: data.medicare_id,
      medicaid_id: data.medicaid_id,
      medicaid_stateid: data.medicaid_stateid,
      ins_notes: data.ins_notes,
      correctionalFacility: false,
      patientid: data.patientid,
      refid: data.refid,
    };

    this.insuranceDetails = details;
  }

  clearReview() {
    this.reviewProps = null;
  }

  clearInsuranceDetails() {
    this.insuranceDetails = null;
  }

  async getReportList(filter: any[]) {
    const { pagination } = this.page;

    const data = filter.concat([pagination.skip, pagination.pageSize]);

    try {
      const response = await apiRequest<LogType[]>({
        url: 'facility.Billingassignment.getFeeForServiceList',
        data,
      });

      const list = response.map((el) => ({
        ...el,
        dos: dateToLocalTimezone({ date: el.dos, dateOnly: true }),
        Studyid: Number(el.Studyid),
        fac_id: Number(el.fac_id),
        facilityid: Number(el.facilityid),
        medicaid_stateid: Number(el.medicaid_stateid),
        patientid: Number(el.patientid),
        radiologistIdsList: Number(el.radiologistIdsList),
        radiologygroupid: Number(el.radiologygroupid),
        refid: Number(el.refid),
        stateid: Number(el.stateid),
      }));
      this.setLogList(list);
    } catch (e: any) {
      this.setLogList([]);
    }
  }

  async getReportCount(data: any[]) {
    try {
      const response = await apiRequest<number>({
        url: 'facility.Billingassignment.getFeeForServiceCount',
        data,
      });

      this.setLogCount(Number(response) || 0);
    } catch (e: any) {
      this.setLogCount(0);
    }
  }

  getReportListMain = (isDivisionEnabled: boolean) => {
    const { filter } = this;

    this.setFetching(true);

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const filterPayload = [
      filter.lastName,
      filter.firstName,
      filter.examType,
      filter.facilityId,
      filter.stateId,
      filter.locationId,
      filter.radiologyGroupId,
      filter.radiologistId,
      filter.pos,
      filter.billProcess,
      filter.period,
      dos.dateFrom,
      dos.dateTo,
      UserProfileStore.getUserType(),
      UserProfileStore.getUserId(),
      filter.providerType,
      isDivisionEnabled ? filter.division : 0,
    ];

    const promiseList = this.getReportList(filterPayload);

    const promiseCount = this.getReportCount(filterPayload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  prepareFilterForExport(isDivisionEnabled: boolean) {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      billProcess: filter.billProcess,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      examType: filter.examType,
      facility: filter.facilityId,
      firstName: filter.firstName,
      lastName: filter.lastName,
      location: filter.locationId,
      period: filter.period,
      pos: filter.pos,
      providerType: filter.providerType,
      radiologist: filter.radiologistId,
      radiologyGroup: filter.radiologyGroupId,
      state: filter.stateId,
      division: isDivisionEnabled ? filter.division : 0,
    };
  }
}

export const storeProFfsReport = new ProFfsReport();
