import Notification from 'components/modal/Notification';
import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from '../options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { calcDateRange } from 'utils/calcDateRange';

export const defaultFilterValue: FilterType = {
  firstName: '',
  lastName: '',
  examType: [],
  providerType: [],
  facility: [],
  orderType: [],
  radiologyGroup: [],
  technologistId: 0,
  state: [],
  ssn: '',
  location: [],
  pos: [],
  ...calcDateRange('M'),
  dob: '',
  period: 'M',
  division: 0,
};

export interface FilterType {
  firstName: string;
  lastName: string;
  examType: number[];
  providerType: number[];
  facility: number[];
  orderType: string[];
  radiologyGroup: number[];
  technologistId: number;
  state: number[];
  ssn: string;
  location: number[];
  pos: number[];
  dosStart: string;
  dosEnd: string;
  dob: string;
  period: string;
  division: number;
}

export interface InsuranceType {
  accessionNumbersStr: string;
  assignvisible: boolean;
  btnvisible: boolean;
  comment: string;
  cptdesc: string;
  cptvisible: boolean;
  dos: string;
  duedate: string;
  examtype: string;
  examtypeid: number;
  externalimgsource: string;
  externaltooltip: string;
  facilitynm: string;
  faximgsource: string;
  faxtooltip: string;
  first_name: string;
  gender: string;
  hasLog: boolean;
  hl7: string | null;
  icddesc: string;
  icdvisible: boolean;
  imgsource: string;
  imgtooltip: string;
  ins_last_verified: null | string;
  insure: string;
  last_name: string;
  location: string;
  orderassigned_date: string;
  ordercompletion_date: string;
  ordercreated_dt: string;
  ordersigned_date: string;
  ordertaken_date: string;
  patient_img_path: string;
  patientdob: string;
  patientid: number;
  patientmrn: string;
  patientnm: string;
  pdfimgvis: boolean;
  phoneimgsource: string;
  phoneresult: string;
  phonetooltip: string;
  pos: string;
  priority: number;
  providerid: string;
  radiologygroupid: string;
  reassignvisible: boolean;
  refid: number;
  resultimgsource: string;
  resulttooltip: string;
  room: string;
  sessionno: string;
  sheduleservdate: string;
  sheduleservdate1: string;
  sheduleservtime1: string;
  signstatus: string;
  signstatusvis: boolean;
  ssn: string;
  state: string;
  status: string;
  techid: number;
  technologistnm: string;
  username: string;
  division: string;
}

interface VerifyInsurancePayloadType {
  isWaiting: boolean;
  selectedIds: number[];
}

class InsuranceVerification {
  fetching: boolean = false;
  insuranceList: InsuranceType[] = [];
  insuranceTotal: number = 0;
  insurancePreview: InsuranceType | null = null;
  idForOrderMessage: number = 0;
  filter: FilterType = defaultFilterValue;
  auditLogProps: InsuranceType | null = null;

  page: Pagination;

  constructor() {
    this.page = new Pagination({
      id: 'claim_management_insurance_verification_grid',
    });

    makeObservable(this, {
      fetching: observable,
      insuranceList: observable,
      insuranceTotal: observable,
      filter: observable,
      insurancePreview: observable,
      idForOrderMessage: observable,
      auditLogProps: observable,

      setFetching: action,
      setInsuranceList: action,
      setInsuranceTotal: action,
      setIdForOrderMessage: action,
      setAuditLogProps: action,
      setFilter: action.bound,
      setInsurancePreview: action.bound,
      clearInsurancePreview: action.bound,
      clearIdForOrderMessage: action.bound,
      clearAuditLogProps: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setInsuranceList(list: InsuranceType[]) {
    this.insuranceList = list;
  }

  setInsuranceTotal(count: number) {
    this.insuranceTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setInsurancePreview(insurance: InsuranceType) {
    this.insurancePreview = insurance;
  }

  setIdForOrderMessage(id: number) {
    this.idForOrderMessage = id;
  }

  setAuditLogProps(insurance: InsuranceType) {
    this.auditLogProps = insurance;
  }

  clearInsurancePreview() {
    this.insurancePreview = null;
  }

  clearIdForOrderMessage() {
    this.idForOrderMessage = 0;
  }

  clearAuditLogProps() {
    this.auditLogProps = null;
  }

  async getInsuranceList(data: any[]) {
    this.setFetching(true);
    try {
      const response = await apiRequest<InsuranceType[]>({
        url: 'order.Order.getBillingOrderList',
        data,
      });

      const list = response.map((insurance) => ({
        ...insurance,
        dos: dateToLocalTimezone({
          date: insurance.dos,
          dateOnly: true,
        }),
        sheduleservdate: dateToLocalTimezone({
          date: insurance.sheduleservdate,
          dateOnly: true,
        }),
        orderassigned_date: dateToLocalTimezone({
          date: insurance.orderassigned_date,
        }),
        ordercreated_dt: dateToLocalTimezone({
          date: insurance.ordercreated_dt,
        }),
        ordercompletion_date: dateToLocalTimezone({
          date: insurance.ordercompletion_date,
        }),
        sessionno: insurance.sessionno.replaceAll(',', ', '),
        examtypeid: Number(insurance.examtypeid),
        patientid: Number(insurance.patientid),
        priority: Number(insurance.priority),
        refid: Number(insurance.refid),
        techid: Number(insurance.techid),
      }));

      this.setInsuranceList(list);
    } catch (e: any) {
      this.setInsuranceList([]);
    } finally {
      this.setFetching(false);
    }
  }

  async getInsuranceCount(data: any[]) {
    this.setFetching(true);
    try {
      const count = await apiRequest<number>({
        url: 'order.Order.getBillingOrderCount',
        data,
      });
      this.setInsuranceTotal(count);
    } catch (e: any) {
      this.setInsuranceTotal(0);
    } finally {
      this.setFetching(false);
    }
  }

  getInsuranceListMain = (isDivisionEnabled: boolean) => {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data: any[] = [
      filter.radiologyGroup,
      filter.lastName,
      filter.dob,
      filter.facility,
      filter.examType,
      dos.dateFrom,
      dos.dateTo,
      filter.providerType,
      null,
      '',
      filter.firstName,
      filter.ssn,
      [],
      [],
      'A',
      filter.state,
      filter.location,
      filter.technologistId,
      filter.pos,
      filter.orderType,
      filter.providerType,
      isDivisionEnabled ? filter.division : 0,
    ];

    this.setFetching(true);

    const promiseCount = this.getInsuranceCount(data);

    const promiseList = this.getInsuranceList(
      data.concat([pagination.skip, pagination.pageSize])
    );

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async verifyInsurance({
    isWaiting,
    selectedIds,
  }: VerifyInsurancePayloadType) {
    const data = [
      isWaiting ? 'Y' : 'N',
      selectedIds.map((refid) => ({ refid })),
    ];

    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'N' | null>({
        url: 'order.Order.UpdateEligibility',
        data,
      });
      if (response === null) throw Error('');

      Notification.success(
        `Order${selectedIds.length > 1 ? 's have' : ' has'} been moved to ${
          isWaiting ? 'waiting' : 'completed'
        } status in eligibility manager`
      );
      return true;
    } catch (e: any) {
      Notification.warning('An error occurred!');
      this.setFetching(false);
      return false;
    }
  }
}

export const storeInsuranceVerification = new InsuranceVerification();
