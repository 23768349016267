import { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import Dialog, {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from 'components/modal/dialog';
import { SpinnerFixed } from 'components/spinner';
import { Textarea } from 'components/form/textarea';
import InterpretationInfo from './InterpretationInfo';
import textPrettier from './textPrettier';
import resolver from './validation';

import { storeInterpretation } from 'stores/_mobx/workflow/order/interpretation';
import { getAppCurrentTimeString, getTimeZoneName } from 'utils/DateUtils';

export interface InterpretationOptions {
  idx: number;
  interpretation: string;
  cpt: string;
  orderId: number;
  cptId: number;
  cptSessionNo: string;
  radiologistId: number;
  reportType: 'P' | 'F' | 'A';
}

interface PropsType {
  options: InterpretationOptions;
  onClose: () => void;
  onSubmit: (fileName: string, interpretation: string) => void;
}

const DialogInterpretation = ({ options, ...rest }: PropsType) => {
  const {
    control,
    formState: { isSubmitting },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: { interpretation: options.interpretation },
    resolver,
  });

  const handleClickReset = () => {
    reset({ interpretation: options.interpretation });
  };

  const onSubmit = ({ interpretation }: { interpretation: string }) => {
    const currentDateTime: string = `${getAppCurrentTimeString()} ${getTimeZoneName()}`;

    const payload = {
      ...options,
      text: interpretation,
      currentDateTime,
    };

    return storeInterpretation
      .addInterpretation(payload)
      .then((filename: string) => {
        rest.onSubmit(filename, interpretation);
      });
  };

  const handleClickSubmit = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const payload = {
      orderId: options.orderId,
      cptCode: options.cpt,
      cptRefid: options.cptId,
      cptSessionNo: options.cptSessionNo,
    };

    storeInterpretation.getInterpretationInfo(payload);

    return storeInterpretation.clearInterpretation;
  }, [options]);

  return (
    <Dialog size="large" handleClose={rest.onClose}>
      <DialogHeader title="Interpretation" onClose={rest.onClose} />
      <DialogBody>
        <Observer>
          {() => (
            <>
              {(storeInterpretation.fetching || isSubmitting) && (
                <SpinnerFixed />
              )}
              <InterpretationInfo info={storeInterpretation.interpretation} />
            </>
          )}
        </Observer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Textarea
            label="CPT Interpretation"
            name="interpretation"
            rows={10}
            autoFocus
            control={control}
          />
          <span className="small">
            {
              'To format text on the PDF in a monospaced font, wrap {mono} around words like this: '
            }
            <br />
            <span
              style={{
                fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
              }}>
              {'{mono}This will be monospaced{mono}'}
            </span>
          </span>
          <button type="submit" className="d-none" />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleClickReset}
        />
        <Button
          text="Save"
          disabled={isSubmitting}
          onClick={handleClickSubmit}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogInterpretation;
