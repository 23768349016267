import { makeObservable, observable, computed, action } from 'mobx';

import Notification from 'components/modal/Notification';
import {
  getInitialValues,
  createFaxBody,
  FaxModel,
} from 'page/components/DialogFormTextFax';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { downloadFile } from 'utils/downloadFile';
import { emailsUrlPrettier } from 'utils/emailValuesFormatter';
import { BASE_URL_FILE_DIR } from 'constant/config';
import {
  dateToLocalTimezone,
  getDateRangeBounds,
  getDayBounds,
} from 'utils/DateUtils';
import { calcDateRange } from 'utils/calcDateRange';

const emailInitialState: EmailDialogFormType = {
  emailsList: '',
  template: 0,
  attachment: '',
  subject: '',
  body: '<p><br></p>',
};

export interface EmailDialogFormType {
  emailsList: string;
  template: number;
  attachment: string;
  subject: string;
  body: string;
}

export const filterDefaultValue: FilterType = {
  facility: [],
  state: [],
  location: [],
  pos: [],
  ...calcDateRange('M'),
  period: 'M',
  emailDate: '',
  faxDate: '',
  providerType: [],
  division: 0,
};

export interface FilterType {
  facility: number[];
  state: number[];
  location: number[];
  pos: number[];
  dosStart: string;
  dosEnd: string;
  period: string;
  emailDate: string;
  faxDate: string;
  providerType: number[];
  division: number;
}

export interface AppointmentType {
  bill_addres1: string;
  bill_city: string;
  bill_contactnm: string;
  bill_phone: null;
  bill_state: string;
  bill_zipcode: string;
  email_date: string;
  facility_add1: string;
  facility_add2: string;
  facility_billemail: string;
  facility_billfax: string;
  facility_billingcontact: string;
  facility_city: string;
  facility_id: number;
  facility_name: string;
  facility_state: number;
  facility_type: string;
  facility_zipcode: string;
  fax_date: string;
  fax_time: string;
  hasLog: boolean;
  notifyemail: string;
  orderid_grp: string;
  unassign_count: number;
  division: string;
}

interface TransmitLogParams {
  facilityId: number;
  facilityName: string;
}

class TransmitUnassignment {
  fetching: boolean = false;
  appointments: AppointmentType[] = [];
  appointmentsTotal: number = 0;
  paramsForTransmitLog: TransmitLogParams | null = null;
  idForNotes: number = 0;
  selectedIdForEmail: number[] = [];
  selectedIdForFax: number[] = [];
  emailDialogProps: EmailDialogFormType | null = null;
  faxDialogProps: FaxModel | null = null;
  filter: FilterType = filterDefaultValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({ id: 'transmit_unassignment_grid' });

    makeObservable(this, {
      fetching: observable,
      appointments: observable,
      appointmentsTotal: observable,
      paramsForTransmitLog: observable,
      idForNotes: observable,
      selectedIdForEmail: observable,
      selectedIdForFax: observable,
      emailDialogProps: observable,
      faxDialogProps: observable,
      filter: observable,

      isAllFaxChecked: computed,
      isAllEmailChecked: computed,

      setFetching: action,
      setAppointments: action,
      setAppointmentsTotal: action,
      setParamsForTransmitLog: action,
      setIdForNotes: action,
      setEmailDialogProps: action.bound,
      setFaxDialogProps: action.bound,
      setFilter: action.bound,
      setIdForFax: action.bound,
      setIdForEmail: action.bound,
      setAllIdForFax: action.bound,
      setAllIdForEmail: action.bound,
      clearParamsForTransmitLog: action.bound,
      clearIdForNotes: action.bound,
      clearSelectedIds: action.bound,
      clearEmailDialogProps: action.bound,
      clearFaxDialogProps: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setAppointments(appointments: AppointmentType[]) {
    this.appointments = appointments;
  }

  setAppointmentsTotal(count: number) {
    this.appointmentsTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setParamsForTransmitLog(params: TransmitLogParams) {
    this.paramsForTransmitLog = params;
  }

  setIdForFax(ids: number[]) {
    this.selectedIdForFax = ids;
  }

  setIdForEmail(ids: number[]) {
    this.selectedIdForEmail = ids;
  }

  setAllIdForFax(isAllSelected: boolean) {
    this.selectedIdForFax = isAllSelected
      ? this.appointments
          .filter(({ facility_billfax }) => Boolean(facility_billfax))
          .map(({ facility_id }) => facility_id)
      : [];
  }

  setAllIdForEmail(isAllSelected: boolean) {
    this.selectedIdForEmail = isAllSelected
      ? this.appointments
          .filter(({ facility_billemail }) => Boolean(facility_billemail))
          .map(({ facility_id }) => facility_id)
      : [];
  }

  setIdForNotes(id: number) {
    this.idForNotes = id;
  }

  setEmailDialogProps() {
    const emailsList = this.selectedIdForEmail
      .map((id) => {
        const appointment = this.appointments.find(
          ({ facility_id }) => facility_id === id
        );

        return appointment?.facility_billemail;
      })
      .filter((email) => Boolean(email))
      .join(', ');

    this.emailDialogProps = {
      ...emailInitialState,
      emailsList,
    };
  }

  setFaxDialogProps() {
    const companyName =
      UserProfileStore.getState()?.userProfile?.admininfo?.[0]?.company_name;
    this.faxDialogProps = {
      ...getInitialValues(),
      body: createFaxBody(
        companyName ? `<p>${companyName}</p>` : '<p><br></p>'
      ),
    };
  }

  clearParamsForTransmitLog() {
    this.paramsForTransmitLog = null;
  }

  clearIdForNotes() {
    this.idForNotes = 0;
  }

  clearSelectedIds() {
    this.selectedIdForEmail = [];
    this.selectedIdForFax = [];
  }

  clearEmailDialogProps() {
    this.emailDialogProps = null;
  }

  clearFaxDialogProps() {
    this.faxDialogProps = null;
  }

  get isAllFaxChecked() {
    const count = this.appointments.reduce(
      (count, { facility_billfax }) => (count += facility_billfax ? 1 : 0),
      0
    );

    return count && count === this.selectedIdForFax.length;
  }

  get isAllEmailChecked() {
    const count = this.appointments.reduce(
      (count, { facility_billemail }) => (count += facility_billemail ? 1 : 0),
      0
    );

    return count && count === this.selectedIdForEmail.length;
  }

  async getAppointmentsCount(data: (string | number | number[])[]) {
    try {
      const count = await apiRequest<string>({
        url: 'facility.Billingassignment.TransmitUnassign_count',
        data,
      });

      this.setAppointmentsTotal(Number(count) || 0);
    } catch (e: any) {
      this.setAppointmentsTotal(0);
    }
  }

  async getAppointmentsList(data: (string | number | number[])[]) {
    try {
      const response = await apiRequest<AppointmentType[]>({
        url: 'facility.Billingassignment.TransmitUnassign_list',
        data,
      });

      const appointments = response.map((appointment) => ({
        ...appointment,
        email_date: dateToLocalTimezone({
          date: appointment.email_date || '',
          dateOnly: true,
        }),
        fax_date: dateToLocalTimezone({
          date: appointment.fax_date || '',
          dateOnly: true,
        }),
        facility_billemail: emailsUrlPrettier(appointment.facility_billemail),
        facility_id: Number(appointment.facility_id),
        facility_state: Number(appointment.facility_state),
        unassign_count: Number(appointment.unassign_count),
      }));

      this.setAppointments(appointments);
    } catch (e: any) {
      this.setAppointments([]);
    }
  }

  getAppointmentsListMain(isDivisionEnabled: boolean) {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const emailDate = getDayBounds(filter.emailDate);

    const faxDate = getDayBounds(filter.faxDate);

    const countPayload = [
      filter.facility,
      0,
      filter.state,
      filter.location,
      filter.pos,
      dos.dateFrom,
      dos.dateTo,
      emailDate.dateFrom,
      emailDate.dateTo,
      faxDate.dateFrom,
      faxDate.dateTo,
      'A',
      filter.providerType,
      isDivisionEnabled ? filter.division : 0,
    ];

    const appointmentPayload = countPayload.concat([
      pagination.skip,
      pagination.pageSize,
    ]);

    this.setFetching(true);

    const promiseCount = this.getAppointmentsCount(countPayload);

    const promiseList = this.getAppointmentsList(appointmentPayload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  exportAppointmentToPdf = async (
    appointment: AppointmentType,
    signal?: AbortSignal
  ) => {
    const payload = {
      bill_city: appointment.bill_city,
      bill_state: appointment.bill_state,
      facility_name: appointment.facility_name,
      bill_addres1: appointment.bill_addres1,
      bill_zipcode: appointment.bill_zipcode,
      bill_contactnm: appointment.bill_contactnm,
      orderid_grp: appointment.orderid_grp,
      from_date: null as null,
      end_date: null as null,
      facility_id: appointment.facility_id,
    };

    try {
      const fileName = await apiRequest<string>({
        url: 'facility.Billingassignment.GenerateTransmitPdf',
        data: [payload],
        signal,
      });

      const link = `${BASE_URL_FILE_DIR}facility/${fileName}`;
      downloadFile(link, true);
    } catch (e: any) {
      if (!signal?.aborted)
        Notification.danger('An error occurred during file generation!');
    }
  };
}

export const storeTransmitUnassignment = new TransmitUnassignment();
