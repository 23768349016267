import { makeObservable, observable, computed, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import {
  downloadExportInvoice,
  downloadExportInvoicePerDiem,
} from 'stores/_mobx/export';
import UserProfileStore from 'stores/UserProfileStore';
import { getDateString } from 'utils/DateUtils';
import { downloadFile } from 'utils/downloadFile';
import { calcDateRange } from 'utils/calcDateRange';
import { BASE_URL_FILE_DIR } from 'constant/config';

const availableUserTypes = ['D', 'L', 'RA', 'RC'];

const calculateInvoicePaid = (invoice: InvoiceResponseType) => {
  if (invoice.total_paid) {
    return invoice.pay_type === 'BULK'
      ? Number(invoice.total_paid)
      : Number(invoice.total_paid) - Number(invoice.writeoff);
  }

  return Number(invoice.paid);
};

export const filterDefaultValue: FilterType = {
  corporateId: 0,
  state: [],
  orderType: '',
  invoicesStatus: '',
  facility: [],
  location: [],
  region: [],
  pos: 0,
  providerType: [],
  invoiceDateFrom: calcDateRange('90').dosStart,
  invoiceDateTo: calcDateRange('90').dosEnd,
  period: 90,
  claim: '',
  invoice: '',
  division: 0,
};

export interface FilterType {
  corporateId: number;
  state: number[];
  orderType: string;
  invoicesStatus: string;
  facility: number[];
  location: number[];
  region: number[];
  pos: number;
  providerType: number[];
  invoiceDateFrom: string;
  invoiceDateTo: string;
  period: string | number;
  claim: string;
  invoice: string;
  division: number;
}

interface InvoiceResponseType {
  address1: string;
  aging: number;
  balance: string;
  bill_email: null;
  discount: string;
  facilityId: string;
  facility_npi: string | null;
  facilitynm: string;
  hasLog: boolean;
  invoice_alphanum: string;
  invoice_dt: string;
  invoice_no: string;
  invoice_upd_count: '0';
  lateFee: number;
  lateFeePaid: number;
  overpaid: string;
  ownername: string;
  paid: string;
  pay_type: string | null;
  perdiemTotal: 0;
  phone: string | null;
  pos: string;
  refid: string | null;
  region: string;
  total_paid: string;
  total_price: string;
  writeoff: string;
  division: string;
}

export interface InvoiceType {
  balance: number;
  discount: number;
  overpaid: number;
  paid: number;
  total_paid: number;
  payType: string;
  totalPaid: number;
  total_price: number;
  writeoff: number;
  facilityId: number;
  facilitynm: string;
  invoiceNumber: number;
  invoice_dt: string;
  invoice_alphanum: string;
  lateFee: number;
  lateFeePaid: number;
  aging: number;
  hasLog: boolean;
  ownerName: string;
  pos: string;
  region: string;
  division: string;
}

interface PropsForBilling {
  facilityId: number;
  invoiceNumber: number;
}

interface PropsInvoiceType extends PropsForBilling {
  invoice_alphanum: string;
  invoice_dt: string;
}

interface PropsForFaxingType {
  facilityName: string;
  facilityId: number;
}

class Invoice {
  fetching: boolean = false;
  invoicesList: InvoiceType[] = [];
  invoicesTotal: number = 0;
  invoiceNumberForAudit: number = 0;
  idForMessages: number = 0;
  invoiceForChargePosting: InvoiceType | null = null;
  propsForDownloadingInvoice: PropsInvoiceType | null = null;
  propsForBilling: PropsForBilling | null = null;
  propsForFaxing: PropsForFaxingType | null = null;
  filter: FilterType = filterDefaultValue;
  abortController: AbortController | null = null;

  pageF: Pagination = new Pagination({ id: 'invoice_grid' });
  pagePD: Pagination = new Pagination({ id: 'invoice_grid' });
  pageOcc: Pagination = new Pagination({ id: 'invoice_grid' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      invoicesList: observable,
      invoicesTotal: observable,
      invoiceForChargePosting: observable,
      invoiceNumberForAudit: observable,
      idForMessages: observable,
      propsForDownloadingInvoice: observable,
      propsForBilling: observable,
      propsForFaxing: observable,
      filter: observable,

      totalSumData: computed,

      setFetching: action,
      setInvoiceList: action,
      setInvoiceTotal: action,
      setPropsForBilling: action,
      setPropsForFaxing: action,
      setInvoiceNumberForAudit: action,
      setPropsForDownloadingInvoice: action,
      setIdForMessages: action,
      setInvoiceForChargePosting: action,
      setFilter: action.bound,
      clearPropsForBilling: action.bound,
      clearPropsForFaxing: action.bound,
      clearInvoiceList: action.bound,
      clearInvoiceNumberForAudit: action.bound,
      clearIdForMessages: action.bound,
      clearInvoiceForChargePosting: action.bound,
      clearPropsForDownloadingInvoice: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setInvoiceList(invoices: InvoiceType[]) {
    this.invoicesList = invoices;
  }

  setInvoiceTotal(count: number) {
    this.invoicesTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setPropsForBilling({ facilityId, invoiceNumber }: InvoiceType) {
    this.propsForBilling = { invoiceNumber, facilityId };
  }

  setPropsForFaxing({ facilityId, facilitynm }: InvoiceType) {
    this.propsForFaxing = {
      facilityName: facilitynm,
      facilityId,
    };
  }

  setInvoiceNumberForAudit(id: number) {
    this.invoiceNumberForAudit = id;
  }

  setIdForMessages(id: number) {
    this.idForMessages = id;
  }

  setInvoiceForChargePosting(invoice: InvoiceType) {
    this.invoiceForChargePosting = invoice;
  }

  setPropsForDownloadingInvoice({
    facilityId,
    invoiceNumber,
    invoice_alphanum,
    invoice_dt,
  }: InvoiceType) {
    this.propsForDownloadingInvoice = {
      facilityId,
      invoiceNumber,
      invoice_alphanum,
      invoice_dt,
    };
  }

  clearInvoiceList() {
    this.invoicesList = [];
    this.invoicesTotal = 0;
  }

  clearPropsForBilling() {
    this.propsForBilling = null;
  }

  clearPropsForFaxing() {
    this.propsForFaxing = null;
  }

  clearInvoiceNumberForAudit() {
    this.invoiceNumberForAudit = 0;
  }

  clearIdForMessages() {
    this.idForMessages = 0;
  }

  clearInvoiceForChargePosting() {
    this.invoiceForChargePosting = null;
  }

  clearPropsForDownloadingInvoice() {
    this.propsForDownloadingInvoice = null;
  }

  get totalSumData() {
    const totalSum = this.invoicesList.reduce(
      (prev, current) => ({
        totalPrice: prev.totalPrice + current.total_price,
        paid: prev.paid + current.total_paid,
        balance: prev.balance + current.balance,
        overpaid: prev.overpaid + current.overpaid,
        discount: prev.discount + current.discount,
        writeoff: prev.writeoff + current.writeoff,
      }),
      {
        totalPrice: 0,
        paid: 0,
        balance: 0,
        overpaid: 0,
        discount: 0,
        writeoff: 0,
      }
    );

    const result = Object.entries(totalSum).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: `$ ${value.toFixed(2)}`,
      }),
      {
        totalPrice: '0.00',
        paid: '0.00',
        balance: '0.00',
        overpaid: '0.00',
        discount: '0.00',
        writeoff: '0.00',
      }
    );

    return [result];
  }

  setDefaultFilter() {
    const isUserHasPermission =
      availableUserTypes.includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .indexOf('corporate') > -1;

    const filter = {
      ...this.filter,
      state: isUserHasPermission ? [] : [UserProfileStore.getStateId()],
      facility: isUserHasPermission ? [] : [UserProfileStore.getFacilityId()],
      pos: isUserHasPermission
        ? this.filter.pos
        : UserProfileStore.getPlaceOfServiceId(),
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const isUserHasPermission =
      availableUserTypes.includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .includes('corporate');

    const filter = {
      ...filterDefaultValue,
      state: isUserHasPermission ? [] : [UserProfileStore.getStateId()],
      facility: isUserHasPermission ? [] : [UserProfileStore.getFacilityId()],
      pos: isUserHasPermission ? 0 : UserProfileStore.getPlaceOfServiceId(),
    };

    return filter;
  }

  async getInvoiceCount(data: any[]) {
    try {
      const count = await apiRequest<string>({
        url: 'facility.FacilityBilling.GetInvoiceListByFacilityBillPaymentcount',
        data,
        signal: this.abortController?.signal,
      });
      this.setInvoiceTotal(Number(count) || 0);
    } catch (e: any) {
      this.setInvoiceTotal(0);
    }
  }

  async getInvoices(data: any[]) {
    try {
      const response = await apiRequest<InvoiceResponseType[]>({
        url: 'facility.FacilityBilling.GetInvoiceListByFacilityBillPayment',
        data,
        signal: this.abortController?.signal,
      });

      const invoices = response.map((invoice) => ({
        aging: invoice.aging,
        balance: Number(invoice.balance),
        discount: Number(invoice.discount),
        overpaid: Number(invoice.overpaid),
        paid: Number(invoice.paid),
        totalPaid: calculateInvoicePaid(invoice),
        total_paid: Number(invoice.total_paid),
        payType: invoice.pay_type,
        total_price: Number(invoice.total_price),
        writeoff: invoice.pay_type === 'BULK' ? 0 : Number(invoice.writeoff),
        facilityId: Number(invoice.facilityId),
        facilitynm: invoice.facilitynm,
        invoiceNumber: Number(invoice.invoice_no),
        lateFee: invoice.lateFee,
        lateFeePaid: invoice.lateFeePaid,
        invoice_dt: invoice.invoice_dt,
        invoice_alphanum: invoice.invoice_alphanum,
        hasLog: invoice.hasLog,
        ownerName: invoice.ownername,
        pos: invoice.pos,
        region: invoice.region,
        division: invoice.division,
      }));

      this.setInvoiceList(invoices);
    } catch (e: any) {
      this.setInvoiceList([]);
    }
  }

  getInvoicesListMain = ({
    invoiceType,
    isDivisionEnabled,
    page,
  }: {
    invoiceType: 'H' | 'N' | 'Y';
    isDivisionEnabled: boolean;
    page: Pagination;
  }) => {
    const { filter } = this;

    const { pagination } = page;

    const division = isDivisionEnabled ? filter.division : 0;

    const commonPayload = [
      filter.facility,
      'N',
      filter.corporateId,
      filter.state,
      filter.orderType,
      filter.location,
      filter.pos,
      filter.invoiceDateFrom,
      filter.invoiceDateTo,
      '',
      filter.claim,
      filter.invoice,
      invoiceType,
      filter.providerType,
      filter.invoicesStatus,
    ];

    const countPayload = commonPayload.concat(['', filter.region, division]);

    const invoicesPayload = commonPayload.concat([
      pagination.skip,
      pagination.pageSize,
      '',
      filter.region,
      division,
    ]);

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();

    this.setFetching(true);

    const promiseCount = this.getInvoiceCount(countPayload);

    const promiseList = this.getInvoices(invoicesPayload);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };

  async generateFacilityInvoicePDF(facilityId: number, invoiceNumber: number) {
    const date = getDateString();

    const data = { facilityId, invoiceNumber, date };

    try {
      const fileName = await apiRequest<string>({
        url: 'facility.BillingPdfGenerator.GenerateFacilityInvoicePDF',
        data,
      });

      downloadFile(`${BASE_URL_FILE_DIR}facility/${fileName}`, true);
    } catch (e: any) {}
  }

  downloadInvoiceDetail = ({
    isReceived,
    isPerDiemType,
  }: {
    isReceived: boolean;
    isPerDiemType: boolean;
  }) => {
    const { invoice_alphanum, invoice_dt, ...rest } =
      this.propsForDownloadingInvoice;

    const insuranceDetail = {
      ...rest,
      isReceived,
      date: invoice_dt,
    };

    return isPerDiemType
      ? downloadExportInvoicePerDiem(insuranceDetail)
      : downloadExportInvoice(insuranceDetail);
  };
}

export const storeInvoice = new Invoice();
