import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Pagination from 'stores/_mobx/options/pagination';
import UserProfileStore from 'stores/UserProfileStore';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { calcDateRange } from 'utils/calcDateRange';

const filterDefaultValue: FilterType = {
  facility: [],
  firstName: '',
  lastName: '',
  ssn: '',
  ...calcDateRange('M'),
  period: 'M',
  corporateId: 0,
  examType: [],
  technologistId: 0,
  state: [],
  location: [],
  pos: [],
  dob: '',
  orderType: [],
  providerType: [],
  codingStatus: 'N',
  radiologyGroup: [],
  finding: '',
  mediaType: '',
  cptCategoryId: 0,
  payer: [],
  assignment: '',
  division: 0,
};

export interface FilterType {
  facility: number[];
  firstName: string;
  lastName: string;
  ssn: string;
  dosStart: string;
  dosEnd: string;
  period: string;
  corporateId: number;
  examType: number[];
  technologistId: number;
  state: number[];
  location: number[];
  pos: number[];
  dob: string;
  orderType: string[];
  providerType: number[];
  codingStatus: string;
  radiologyGroup: number[];
  finding: string;
  mediaType: string;
  cptCategoryId: number;
  payer: number[];
  assignment: string;
  division: number;
}

interface CptType {
  accession_number: string;
  cpt_code: string;
  cpt_desc: string;
  cpt_description: string;
  external_accession_number: string;
  extremity: string;
  flag_need_addendum: boolean;
  need_addendum_from_radiology_group_id: number;
  order_initial_cpt_refid: number;
  refid: string;
  session_no: string;
}

interface CodingResponseType {
  GetFnlCptArr: unknown[];
  GetIntCptArr: CptType[];
  accessionNumbersStr: string;
  accessionNumbersStrForReport: string;
  acknowledgement: string;
  address1: string;
  address2: string;
  alert_facility: string;
  assign_providergrpid: null | string;
  assign_providerid: null | string;
  assignflag: boolean;
  bill_email: string;
  btnvisible: boolean;
  city: string;
  clientMRN: string;
  coded_uname: string;
  coding_status: string;
  comment: string;
  correctionalFacility: boolean;
  cptCodes: CptType[];
  cpt_code: string;
  cpt_code1: string[];
  cpt_code_session_no2: string[];
  cpt_refid: string[];
  cptcount: number;
  cptdesc: string;
  cptvisible: boolean;
  critical: '1';
  dateofservice: string;
  dos: string;
  duedate: string;
  enableEx: boolean;
  enableFax: boolean;
  enableImg: boolean;
  enablePh: boolean;
  enableRep: boolean;
  eunity: string;
  exam_symptoms: string;
  examtype: string;
  examtypeid: '1';
  external_accession_number: string;
  externalimgsource: string;
  externaltooltip: string;
  face_sheet: string;
  facility_addtype: string;
  facility_npi: string;
  facility_stat: string;
  facility_type: string;
  facilityid: '27997';
  facilitynm: string;
  faxed_time: string;
  faximgsource: string;
  faxtooltip: string;
  first_name: string;
  fnlcptdesc: string;
  gender: string;
  group_affiliation_description: string;
  group_affiliation_name: string;
  hasLog: boolean;
  hasMedicalLog: boolean;
  hl7: null | string;
  icd: [];
  icdvisible: boolean;
  iceNumber: string;
  imgnotes: string;
  imgsource: string;
  imgtooltip: string;
  imgvisible: boolean;
  immigrationNumber: string;
  inmateNumber: string;
  ins_last_verified: string;
  ins_notes: string;
  insure: string;
  isfinding: 'N' | 'Y';
  lastModifiedLock: string;
  last_name: string;
  lblvalue: string;
  lblvisible: true;
  location: string;
  medicaid_id: '0';
  medicaid_stateid: '10';
  medicare_id: string;
  modality_media_type: string;
  notes: string;
  number_of_patient: '0';
  orderType: string;
  order_based: string;
  order_based_type: string;
  order_media_type: string;
  order_source: string;
  order_source_nm: string;
  orderassigned_date: string;
  ordercompletion_date: string;
  ordercreated_dt: string;
  ordersigned_date: string;
  ordersrc: string;
  orderstatus: string;
  ordertaken_date: string;
  patient_alert: string;
  patient_comment: string;
  patient_diff_address: string;
  patient_diff_city: string;
  patient_diff_phone: string;
  patient_diff_state: string;
  patient_diff_zipcode: string;
  patient_img_path: string;
  patientdob: string;
  patientid: '1';
  patientmrn: '1';
  patientnm: string;
  pdfimgvis: boolean;
  phone: string;
  phoneResult: string;
  phoneimgsource: string;
  phoneresult: string;
  phonetooltip: string;
  phynpi: string;
  physicianname: string;
  phyuserinfoid: '3';
  pos: string;
  pos_id: '0';
  priority: '3';
  priority_nm: string;
  procity: string;
  prostate: string;
  providerName: string;
  providerid: '6';
  radiologygroupid: string;
  radiologygrouprefid: '3';
  reasonval: string;
  refid: string;
  resultimgsource: string;
  resulttooltip: string;
  room: '26';
  row_color: string;
  scheduleendtime: string;
  schedulestime: string;
  sessionno: string;
  sheduleservdate: string;
  sheduleservdate1: string;
  sheduleservtime1: string;
  signstatus: string;
  signstatusvis: boolean;
  spoke_with: string;
  ssn: string;
  state: string;
  status: string;
  status_flag: string;
  techid: '8';
  technologistnm: string;
  technologyname: string;
  techtooltip: string;
  transRefid: '233';
  username: string;
  vw_patientid: string;
  zipcode: string;
  division: string;
}

export interface CodingType extends Omit<CodingResponseType, 'refid'> {
  refid: number;
}

interface AuditLogType
  extends Pick<
    CodingType,
    'refid' | 'accessionNumbersStr' | 'first_name' | 'last_name'
  > {}

class Coding {
  fetching: boolean = false;
  codingList: CodingType[] = [];
  codingTotal: number = 0;
  codingForViewing: CodingType | null = null;
  codingForAuditLog: AuditLogType | null = null;
  orderForDocsPreview: CodingType | null = null;
  idForMessaging: number = 0;
  filter: FilterType = filterDefaultValue;

  page: Pagination;

  constructor() {
    this.page = new Pagination({
      id: 'claim_management_coding_grid',
    });

    makeObservable(this, {
      fetching: observable,
      codingList: observable,
      codingTotal: observable,
      codingForViewing: observable,
      codingForAuditLog: observable,
      idForMessaging: observable,
      orderForDocsPreview: observable,
      filter: observable,

      setFetching: action,
      setCodingList: action,
      setCodingTotal: action,
      setIdForMessaging: action,
      setCodingForAuditLog: action,
      setOrderForDocumentsPreview: action,
      setFilter: action.bound,
      setCodingForViewing: action.bound,
      clearCodingForAuditLog: action.bound,
      clearCodingForViewing: action.bound,
      clearIdForMessaging: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setCodingList(list: CodingType[]) {
    this.codingList = list;
  }

  setCodingTotal(count: number) {
    this.codingTotal = count;
  }

  setFilter(filter: FilterType) {
    this.filter = filter;
  }

  setIdForMessaging(id: number) {
    this.idForMessaging = id;
  }

  setOrderForDocumentsPreview(order: CodingType) {
    this.orderForDocsPreview = order;
  }

  setCodingForAuditLog({
    refid,
    accessionNumbersStr,
    first_name,
    last_name,
  }: CodingType) {
    this.codingForAuditLog = {
      refid,
      accessionNumbersStr,
      first_name,
      last_name,
    };
  }

  setCodingForViewing(coding: CodingType) {
    this.codingForViewing = coding;
  }

  clearCodingForAuditLog() {
    this.codingForAuditLog = null;
  }

  clearCodingForViewing() {
    this.codingForViewing = null;
    this.orderForDocsPreview = null;
  }

  clearIdForMessaging() {
    this.idForMessaging = 0;
  }

  setDefaultFilter() {
    const facility = UserProfileStore.getFacilityId()
      ? [UserProfileStore.getFacilityId()]
      : this.filter.facility;

    const filter = {
      ...this.filter,
      facility,
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const facility = UserProfileStore.getFacilityId()
      ? [UserProfileStore.getFacilityId()]
      : [];

    const filter = {
      ...filterDefaultValue,
      facility,
    };

    return filter;
  }

  async getCodingCount(isDivisionEnabled: boolean) {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      filter.facility,
      filter.firstName,
      filter.lastName,
      filter.ssn,
      dos.dateFrom,
      dos.dateTo,
      'A',
      filter.corporateId,
      filter.examType,
      filter.technologistId || '',
      filter.state,
      filter.location,
      filter.pos,
      filter.dob,
      filter.orderType,
      filter.providerType,
      filter.codingStatus,
      filter.radiologyGroup,
      filter.finding,
      null,
      filter.mediaType,
      filter.cptCategoryId || '',
      filter.payer,
      filter.assignment || null,
      isDivisionEnabled ? filter.division : 0,
    ];

    try {
      const count = await apiRequest<string>({
        url: 'facility.FacilityBilling.CodingReviewCount',
        data,
      });
      this.setCodingTotal(Number(count) || 0);
    } catch (e: any) {
      this.setCodingTotal(0);
    }
  }

  async getCodingList(isDivisionEnabled: boolean) {
    const {
      filter,
      page: { pagination },
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = [
      filter.facility,
      filter.firstName,
      filter.lastName,
      filter.ssn,
      dos.dateFrom,
      dos.dateTo,
      'A',
      filter.corporateId,
      filter.examType,
      filter.technologistId || '',
      filter.state,
      filter.location,
      filter.pos,
      filter.dob,
      filter.orderType,
      filter.providerType,
      pagination.skip,
      pagination.pageSize,
      filter.codingStatus,
      filter.radiologyGroup,
      filter.finding,
      null,
      filter.mediaType,
      filter.cptCategoryId || '',
      filter.payer,
      filter.assignment || null,
      isDivisionEnabled ? filter.division : 0,
    ];

    try {
      const response = await apiRequest<CodingType[]>({
        url: 'facility.FacilityBilling.CodingReviewList',
        data,
      });

      const codingList = response.map((order) => ({
        ...order,
        refid: Number(order.refid),
        dos: dateToLocalTimezone({
          date: order.sheduleservdate,
          dateOnly: true,
        }),
        sheduleservdate: dateToLocalTimezone({
          date: order.sheduleservdate,
          dateOnly: true,
        }),
        orderassigned_date: dateToLocalTimezone({
          date: order.orderassigned_date,
        }),
        ordercreated_dt: dateToLocalTimezone({
          date: order.ordercreated_dt,
        }),
        ordercompletion_date: dateToLocalTimezone({
          date: order.ordercompletion_date,
        }),
      }));
      this.setCodingList(codingList);
    } catch (e: any) {
      this.setCodingList([]);
    }
  }

  getCodingListMain = (isDivisionEnabled: boolean) => {
    this.setFetching(true);

    const promiseList = this.getCodingList(isDivisionEnabled);

    const promiseCount = this.getCodingCount(isDivisionEnabled);

    Promise.allSettled([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  };
}

export const storeCoding = new Coding();
