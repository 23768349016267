import { makeObservable, observable, action, set } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import { TSignOrderItem } from 'services/clinician-manager/sign-orders/SignOrdersPageService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import {
  storeDivisionSettingsStatus,
  DivisionSettingsStatus,
} from 'stores/_mobx/systemSetup/masterSetting/divisionMaster';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { tcBillTypeMapper } from 'utils/tcBillProcessMapper';
import { calcDateRange } from 'utils/calcDateRange';
import { TCBillProcessType } from 'types';

export const tcBillingOptions: OptionType[] = [
  { value: 'F', label: 'Facility' },
  { value: 'I', label: 'Insurance' },
  { value: 'O', label: 'Other' },
  { value: 'H', label: 'Hospice' },
  { value: 'S', label: 'Self Pay' },
  { value: 'R', label: 'Correctional' },
  { value: 'C', label: 'Occ. Health Care' },
  { value: 'B', label: 'Non-Billable' },
];

export const pcBillingOptions: OptionType[] = [
  { value: 'I', label: 'Insurance' },
  { value: 'T', label: 'FFS' },
  { value: 'P', label: 'Purchase Service' },
  { value: 'F', label: 'Facility' },
  { value: 'C', label: 'Occ. Health care' },
  { value: 'O', label: 'Other' },
  { value: 'B', label: 'Non-Billable' },
];

const pcBillTypeMapper: Record<PCBillProcessType, string> = {
  I: 'Ins. Claim',
  T: 'Contract(FFS)',
  P: 'Purchase Service(FFS)',
  F: 'Invoice',
  B: 'N/A',
  C: 'FSS',
  O: 'FFS',
  H: '',
  N: '',
  '': '',
};

const defaultTcFilterValues: FilterTCType = {
  patientFirstName: '',
  patientLastName: '',
  pos: [],
  providerTypeId: [],
  examTypeId: [],
  facilityId: [],
  stateId: [],
  locationId: [],
  radiologyGroupId: [],
  radiologistId: 0,
  orderTypeId: [],
  assigned: 'N',
  billProcess: '',
  ...calcDateRange('M'),
  period: 'M',
  payerId: [],
  division: 0,
};

const defaultPcFilterValues: FilterPCType = {
  patientFirstName: '',
  patientLastName: '',
  pos: [],
  providerTypeId: [],
  examTypeId: [],
  facilityId: [],
  stateId: [],
  locationId: [],
  radiologyGroupId: [],
  radiologistId: 0,
  orderTypeId: [],
  assigned: 'N',
  tcBillProcess: '',
  pcBillProcess: '',
  ...calcDateRange('M'),
  period: 'M',
  payerId: [],
  division: 0,
};

export interface OptionType {
  value: BillingAssignmentCommonType['responsible'];
  label: string;
}

type PCBillProcessType =
  | ''
  | 'B'
  | 'C'
  | 'F'
  | 'H'
  | 'I'
  | 'N'
  | 'O'
  | 'P'
  | 'T';

type EligibilityStatusType =
  | 'Active'
  | 'Waiting'
  | 'Processing'
  | 'Incomplete'
  | 'Completed'
  | 'Not Required'
  | 'Not Available';

type CodingStatusType = 'New' | 'Incomplete' | 'Completed' | 'Not Available';

interface PermissionType {
  isClient: boolean;
  updatingFaceSheet: boolean;
  viewAllColumns: boolean;
}

interface ReviewParamsType {
  facilityId: number;
  patientId: number;
}

interface AuditLogParamsType {
  refid: number;
  last_name: string;
  first_name: string;
}

interface AssignmentPayload {
  claimUniqId: string;
  studyId: number;
  patientId: number;
  pcBillProcess: PCBillProcessType;
  tcBillProcess: TCBillProcessType;
}

export interface FilterTCType {
  patientFirstName: string;
  patientLastName: string;
  pos: number[];
  providerTypeId: number[];
  examTypeId: number[];
  facilityId: number[];
  stateId: number[];
  locationId: number[];
  radiologyGroupId: number[];
  radiologistId: number;
  orderTypeId: number[];
  assigned: 'N' | 'A';
  billProcess: string;
  dosStart: string;
  dosEnd: string;
  period: string;
  payerId: number[];
  division: number;
}

export interface FilterPCType extends Omit<FilterTCType, 'billProcess'> {
  tcBillProcess: string;
  pcBillProcess: string;
}

interface CommonResponseType {
  Patientid: string;
  birthdate: string;
  claimUniqId: string;
  clientMRN: string;
  dos: string;
  examtype: string;
  facility_invoice_status: string;
  facilityid: string;
  facilityname: string;
  file_upload_status: 'Available' | 'Not Available';
  gender: 'Female' | 'Male' | 'Others';
  hasLog: boolean;
  location: string;
  maxrefid: string;
  orderstatus: string;
  order_invoice_status: string;
  order_detail_id: string;
  patient_img_path: string;
  patientfist: string;
  patientid: string;
  patientlast: string;
  patientmrn: string;
  patientname: string;
  pos: string;
  prof_guarantor: string;
  profession: 'Y' | 'N';
  providerid: string;
  radiologist: string;
  radiologistIdsList: null;
  state: string;
  stateid: string;
  studyId: number;
  tcbillprocess: TCBillProcessType;
  techid: string;
  vw_patientid: string;
  division: string;
}

interface PCResponseType extends CommonResponseType {
  coding_status: CodingStatusType;
  eligibility_status: EligibilityStatusType;
  facilitynm: string;
  has_notes: true;
  pcbillprocess: PCBillProcessType;
  profe_guarantor: string;
  radioname: string;
  showimage: string;
  tooltip: null;
  tooltipcnt: number;
  responsible: PCBillProcessType;
}

interface TCResponseType extends CommonResponseType {
  facility_type: string;
  patientnm: string;
  radiologyGroupName: string;
  responsible: TCBillProcessType;
}

export interface BillingAssignmentCommonType {
  studyId: number;
  dos: string;
  billType: string;
  responsible: TCBillProcessType | PCBillProcessType;
  patientLastName: string;
  patientFirstName: string;
  birthDate: string;
  facilityName: string;
  facilityId: number;
  providerName: string;
  pos: string;
  modality: string;
  claimUniqId: string;
  patientId: number;
  clientMRN: string;
  state: string;
  location: string;
  radiologist: string;
  radiologyGroupName: string;
  prof_guarantor: string;
  profession: 'Y' | 'N';
  hasLog: boolean;
  file_upload_status: 'Available' | 'Not Available';
  division: string;
}

export interface BillingAssignmentPcType extends BillingAssignmentCommonType {
  eligibilityStatus: EligibilityStatusType;
  coding_status: CodingStatusType;
  radiologyGroupName: string;
  tcBillProcess: string;
}

class BillingAssignment {
  fetching: boolean = false;
  tcBillingAssignments: BillingAssignmentCommonType[] = [];
  tcBillingAssignmentsTotal: number = 0;
  pcBillingAssignments: BillingAssignmentPcType[] = [];
  pcBillingAssignmentsTotal: number = 0;
  filterTC: FilterTCType = defaultTcFilterValues;
  filterPC: FilterPCType = defaultPcFilterValues;
  pageTC: Pagination = new Pagination({ id: 'billing_assignment_grid_tc' });
  pagePC: Pagination = new Pagination({ id: 'billing_assignment_grid_pc' });
  permission: PermissionType = null;
  visitDetails: any = null;
  paramsForReview: ReviewParamsType | null = null;
  paramsForAuditLog: AuditLogParamsType | null = null;
  idForNotes: number = 0;

  storeDivisionSettingsStatus: DivisionSettingsStatus | null = null;

  constructor(storeDivisionSettingsStatus: DivisionSettingsStatus) {
    makeObservable(this, {
      fetching: observable,
      tcBillingAssignments: observable,
      pcBillingAssignments: observable,
      tcBillingAssignmentsTotal: observable,
      pcBillingAssignmentsTotal: observable,
      filterTC: observable,
      filterPC: observable,
      permission: observable,
      visitDetails: observable,
      paramsForReview: observable,
      paramsForAuditLog: observable,
      idForNotes: observable,

      setFetching: action,
      setTCBillingAssignments: action,
      setPCBillingAssignments: action,
      setTCBillingAssignmentsTotal: action,
      setPCBillingAssignmentsTotal: action,
      setTCFilter: action,
      setPCFilter: action,
      setPermission: action,
      setVisitDetails: action,
      setParamsForReview: action,
      setParamsForAuditLog: action,
      setIdForNotes: action,
      updateBillProcess: action,
      clearVisitDetails: action.bound,
      clearParamsForReview: action.bound,
      clearParamsForAuditLog: action.bound,
      clearIdForNotes: action.bound,
      clearTabData: action.bound,
    });

    this.storeDivisionSettingsStatus = storeDivisionSettingsStatus;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setTCBillingAssignments(assignments: BillingAssignmentCommonType[]) {
    this.tcBillingAssignments = assignments;
  }

  setPCBillingAssignments(assignments: BillingAssignmentPcType[]) {
    this.pcBillingAssignments = assignments;
  }

  setTCBillingAssignmentsTotal(count: number) {
    this.tcBillingAssignmentsTotal = count;
  }

  setPCBillingAssignmentsTotal(count: number) {
    this.pcBillingAssignmentsTotal = count;
  }

  setTCFilter(filter: FilterTCType) {
    this.filterTC = filter;
  }

  setPCFilter(filter: FilterPCType) {
    this.filterPC = filter;
  }

  setPermission(permission: PermissionType) {
    this.permission = permission;
  }

  setVisitDetails(details: any) {
    this.visitDetails = details;
  }

  setParamsForReview({ facilityId, patientId }: BillingAssignmentCommonType) {
    this.paramsForReview = {
      facilityId,
      patientId,
    };
  }

  setParamsForAuditLog(props: BillingAssignmentCommonType) {
    this.paramsForAuditLog = {
      refid: props.studyId,
      first_name: props.patientFirstName,
      last_name: props.patientLastName,
    };
  }

  setIdForNotes(id: number) {
    this.idForNotes = id;
  }

  clearVisitDetails() {
    this.visitDetails = null;
  }

  clearParamsForReview() {
    this.paramsForReview = null;
  }

  clearParamsForAuditLog() {
    this.paramsForAuditLog = null;
  }

  clearIdForNotes() {
    this.idForNotes = 0;
  }

  clearTabData() {
    this.clearVisitDetails();
    this.clearParamsForReview();
    this.clearParamsForAuditLog();
    this.clearIdForNotes();
    this.permission = null;
  }

  updateBillProcess(props: {
    id: string;
    responsible: TCBillProcessType | PCBillProcessType;
    isTcBillProcess: boolean;
  }) {
    const key = props.isTcBillProcess
      ? 'tcBillingAssignments'
      : 'pcBillingAssignments';

    const billingAssignments = this[key];

    const assignment = billingAssignments.find(
      (el) => el.claimUniqId === props.id
    );

    set(assignment, 'responsible', props.responsible);
  }

  updateBillProcessForAll(props: {
    responsible: TCBillProcessType | PCBillProcessType;
    isTcBillProcess: boolean;
  }) {
    const key = props.isTcBillProcess
      ? 'tcBillingAssignments'
      : 'pcBillingAssignments';

    const billingAssignments = this[key];

    const assignments = billingAssignments.map((el) => ({
      ...el,
      responsible: props.responsible,
    }));

    if (props.isTcBillProcess) this.setTCBillingAssignments(assignments);
    // @ts-ignore
    else this.setPCBillingAssignments(assignments);
  }

  setDefaultFilter() {
    const isUserHasPermission =
      ['D', 'L', 'RA', 'RC'].includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .includes('corporate');

    [this.filterTC, this.filterPC].forEach((currentFilter, idx) => {
      const filter = {
        ...currentFilter,
        assigned: UserProfileStore.isClientUser()
          ? 'N'
          : currentFilter.assigned,
        stateId: isUserHasPermission
          ? currentFilter.stateId
          : [UserProfileStore.getStateId()],
        facilityId: isUserHasPermission
          ? currentFilter.facilityId
          : [UserProfileStore.getFacilityId()],
        pos: isUserHasPermission
          ? currentFilter.pos
          : UserProfileStore.getPlaceOfServiceId(),
      };

      if (!idx) this.setTCFilter(filter as FilterTCType);
      else this.setPCFilter(filter as FilterPCType);
    });
  }

  getDefaultFilter<T extends boolean>(
    isTcBillProcess: T
  ): T extends true ? FilterTCType : FilterPCType {
    const isUserHasPermission =
      ['D', 'L', 'RA', 'RC'].includes(UserProfileStore.getUserType()) ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .includes('corporate');

    const filter = {
      ...(isTcBillProcess ? defaultTcFilterValues : defaultPcFilterValues),
      stateId: isUserHasPermission ? [] : [UserProfileStore.getStateId()],
      facilityId: isUserHasPermission ? [] : [UserProfileStore.getFacilityId()],
      pos: isUserHasPermission ? 0 : UserProfileStore.getPlaceOfServiceId(),
    };
    // @ts-ignore
    return filter;
  }

  checkPermission() {
    const userType = UserProfileStore.getUserType();

    const isClient = UserProfileStore.isClientUser();

    const updatingFaceSheet = userType === 'A' || userType === 'FC'; // 'A': facility admin; 'FC': facility billing coordinator

    const permission = {
      isClient,
      updatingFaceSheet,
      viewAllColumns: !updatingFaceSheet,
    };

    this.setPermission(permission);
  }

  async getTCBillingAssignmentCount(data: (number | string | number[])[]) {
    try {
      const count = await apiRequest<string>({
        url: 'facility.FacilityBilling.getTcAssignmentCount',
        data,
      });

      this.setTCBillingAssignmentsTotal(Number(count) || 0);
    } catch (error) {
      this.setTCBillingAssignmentsTotal(0);
    }
  }

  async getTCBillingAssignments(data: (number | string | number[])[]) {
    try {
      const response = await apiRequest<TCResponseType[]>({
        url: 'facility.FacilityBilling.getTcAssignmentList',
        data,
      });

      const assignments = response.map((el) => ({
        studyId: el.studyId,
        dos: dateToLocalTimezone({ date: el.dos, dateOnly: true }),
        billType:
          el.responsible === 'F'
            ? el.facilityname
            : tcBillingOptions.find(({ value }) => value === el.responsible)
                ?.label || '',
        responsible: el.responsible,
        patientLastName: el.patientlast,
        patientFirstName: el.patientfist,
        birthDate: el.birthdate,
        facilityName: el.facilityname,
        facilityId: Number(el.facilityid),
        providerName: el.providerid,
        pos: el.pos,
        modality: el.examtype,
        claimUniqId: el.claimUniqId,
        patientId: Number(el.patientid),
        clientMRN: el.clientMRN,
        state: el.state,
        location: el.location,
        radiologist: el.radiologist,
        radiologyGroupName: el.radiologyGroupName,
        prof_guarantor: el.prof_guarantor,
        profession: el.profession,
        hasLog: el.hasLog,
        file_upload_status: el.file_upload_status,
        division: el.division,
      }));

      this.setTCBillingAssignments(assignments);
    } catch (error) {
      this.setTCBillingAssignments([]);
    }
  }

  getTCBillingAssignmentsMain() {
    const {
      filterTC,
      pageTC: { pagination },
      storeDivisionSettingsStatus: { isDivisionEnabled },
    } = this;

    const dos = getDateRangeBounds({
      from: filterTC.dosStart,
      to: filterTC.dosEnd,
    });

    const data = [
      filterTC.facilityId,
      filterTC.patientFirstName,
      filterTC.patientLastName,
      filterTC.pos,
      dos.dateFrom,
      dos.dateTo,
      '', //filterTC.dob,
      0, //filterTC.corporateId,
      filterTC.examTypeId,
      filterTC.assigned,
      filterTC.stateId,
      filterTC.locationId,
      filterTC.radiologyGroupId,
      filterTC.radiologistId,
      filterTC.orderTypeId,
      filterTC.providerTypeId,
      filterTC.billProcess,
      filterTC.payerId,
      isDivisionEnabled ? filterTC.division : 0,
    ];

    this.setFetching(true);

    const promiseCount = this.getTCBillingAssignmentCount(data);

    const promiseList = this.getTCBillingAssignments([
      ...data,
      pagination.skip,
      pagination.pageSize,
    ]);

    Promise.all([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getPCBillingAssignmentCount(data: (number | string | number[])[]) {
    try {
      const count = await apiRequest<string>({
        url: 'facility.FacilityBilling.getPcAssignmentCount',
        data,
      });

      this.setPCBillingAssignmentsTotal(Number(count) || 0);
    } catch (error) {
      this.setPCBillingAssignmentsTotal(0);
    }
  }

  async getPCBillingAssignments(data: (number | string | number[])[]) {
    try {
      const response = await apiRequest<PCResponseType[]>({
        url: 'facility.FacilityBilling.getPcAssignmentList',
        data,
      });

      const assignments = response.map((el) => ({
        studyId: el.studyId,
        dos: dateToLocalTimezone({ date: el.dos, dateOnly: true }),
        billType:
          el.responsible === 'F'
            ? `${pcBillTypeMapper[el.responsible]} (${el.facilityname})`
            : pcBillTypeMapper[el.responsible] || '',
        responsible: el.responsible,
        tcBillProcess: tcBillTypeMapper[el.tcbillprocess] || '',
        patientLastName: el.patientlast,
        patientFirstName: el.patientfist,
        birthDate: el.birthdate,
        facilityName: el.facilityname,
        facilityId: Number(el.facilityid),
        providerName: el.providerid,
        pos: el.pos,
        modality: el.examtype,
        claimUniqId: el.claimUniqId,
        patientId: Number(el.patientid),
        clientMRN: el.clientMRN,
        state: el.state,
        location: el.location,
        radiologist: el.radiologist,
        prof_guarantor: el.prof_guarantor,
        profession: el.profession,
        hasLog: el.hasLog,
        file_upload_status: el.file_upload_status,
        eligibilityStatus: el.eligibility_status,
        coding_status: el.coding_status,
        radiologyGroupName: el.radioname,
        division: el.division,
      }));

      this.setPCBillingAssignments(assignments);
    } catch (error) {
      this.setPCBillingAssignments([]);
    }
  }

  getPCBillingAssignmentsMain() {
    const {
      filterPC,
      pagePC: { pagination },
      storeDivisionSettingsStatus: { isDivisionEnabled },
    } = this;

    const dos = getDateRangeBounds({
      from: filterPC.dosStart,
      to: filterPC.dosEnd,
    });

    const data = [
      filterPC.facilityId,
      filterPC.patientFirstName,
      filterPC.patientLastName,
      filterPC.pos,
      dos.dateFrom,
      dos.dateTo,
      '', //filterPC.dob,
      0, //filterPC.corporateId,
      filterPC.examTypeId,
      filterPC.assigned,
      filterPC.stateId,
      filterPC.locationId,
      filterPC.radiologyGroupId,
      filterPC.radiologistId,
      filterPC.orderTypeId,
      filterPC.providerTypeId,
      filterPC.pcBillProcess,
      filterPC.tcBillProcess,
      filterPC.payerId,
      isDivisionEnabled ? filterPC.division : 0,
    ];

    this.setFetching(true);

    const promiseCount = this.getPCBillingAssignmentCount(data);

    const promiseList = this.getPCBillingAssignments([
      ...data,
      pagination.skip,
      pagination.pageSize,
    ]);

    Promise.all([promiseCount, promiseList]).finally(() => {
      this.setFetching(false);
    });
  }

  async getVisitDetails(id: number) {
    this.setFetching(true);
    try {
      const [response] = await apiRequest<[TSignOrderItem]>({
        url: 'order.Order.GetOrderById',
        data: [id],
      });

      const dos = dateToLocalTimezone({ date: response.dos, dateOnly: true });

      const orderassigned_date = dateToLocalTimezone({
        date: `${response.orderassigned_date} ${
          // @ts-ignore
          response.orderassigned_time || ''
        }`.trim(),
      });

      const ordercreated_dt = dateToLocalTimezone({
        date: response.ordercreated_dt,
      });

      const ordercompletion_date = dateToLocalTimezone({
        date: `${response.ordercompletion_date} ${
          // @ts-ignore
          response.ordercompletion_time || ''
        }`,
      });

      const visitDetails = {
        ...response,
        dos,
        orderassigned_date,
        ordercreated_dt,
        ordercompletion_date,
      };

      this.setVisitDetails(visitDetails);
    } catch (e: any) {
      this.setVisitDetails(null);
    } finally {
      this.setFetching(false);
    }
  }

  async updateProfession(props: { profession: 'Y' | 'N'; id: number }) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'SE' | 'S'>({
        url: 'facility.FacilityBilling.UpdateOrderByStudId',
        data: [props.profession, props.id],
      });
      if (response === 'S') {
        Notification.success('Profession updated!');
      }
    } catch (e: any) {
      Notification.danger('Error updating profession!');
    }
  }

  async updateFaceSheet(props: { id: number; fileName: string }) {
    try {
      await apiRequest<string>({
        url: 'facility.FacilityBilling.UpdateFaceSheetOrder',
        data: [props.id, props.fileName],
      });

      Notification.success('Face Sheet was updated');
    } catch (e: any) {
      Notification.danger('Face Sheet uploading error');
    }
  }

  async reassignBillingAssignment(props: {
    assignments: AssignmentPayload[];
    assigned: 'Y' | 'N';
    isTcAssignment: boolean;
  }) {
    this.setFetching(true);
    try {
      const response = await apiRequest<'S' | 'SE'>({
        url: props.isTcAssignment
          ? 'facility.FacilityBilling.AssignTcAssignment'
          : 'facility.FacilityBilling.AssignPcAssignment',
        data: [props.assignments, props.assigned === 'N' ? 'N' : ''],
      });

      if (response === 'S') {
        Notification.success('Order assigned successfully');
        return true;
      }
      throw Error('');
    } catch (e: any) {
      this.setFetching(false);
      Notification.danger('Error of order assigning!');
      return false;
    }
  }

  prepareFilterForExport(isTcBillProcess: boolean) {
    const {
      filterPC,
      filterTC,
      storeDivisionSettingsStatus: { isDivisionEnabled },
    } = this;

    const filter = isTcBillProcess ? filterTC : filterPC;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return [
      filter.facilityId,
      filter.patientFirstName,
      filter.patientLastName,
      filter.pos,
      dos.dateFrom,
      dos.dateTo,
      '',
      0,
      filter.examTypeId,
      filter.assigned,
      filter.stateId,
      filter.locationId,
      filter.radiologyGroupId,
      filter.radiologistId,
      filter.orderTypeId,
      filter.providerTypeId,
      ...(isTcBillProcess
        ? // @ts-ignore
          [filter.billProcess || null, filter.payerId || null]
        : [
            // @ts-ignore
            filter.pcBillProcess || null,
            // @ts-ignore
            filter.tcBillProcess || null,
            filter.payerId || null,
          ]),
      isDivisionEnabled ? filter.division : 0,
    ];
  }
}

export const storeBillingAssignment = new BillingAssignment(
  storeDivisionSettingsStatus
);
