import React, { useMemo, useState } from 'react';
import { RouteComponentProps, Switch } from 'react-router-dom';

import Tabs from 'components/tabs';
import AccessUtils from 'utils/AccessUtils';
import PlacedOrderContainer from 'page/workflow/order/PlacedOrder';
import UnassignedOrdersContainer from 'page/workflow/order/UnassignedOrders';
import AssignedOrdersContainer from 'page/workflow/order/AssignedOrders';
import ReportManagerContainer from 'page/workflow/order/ReportManager';
import CallbackManagerContainer from 'page/workflow/order/CallbackManager';
import VisitScheduleContainer from 'page/workflow/order/VisitSchedule';
import ExamReviewContainer from 'page/workflow/order/ExamReview';
import {
  AccessRoute,
  withDivisionSettings,
  PropsTypeWitDivisionSettings,
} from 'components/HOC';
import * as IDS from 'constant/pagesId/workflow';
import {
  URL_ORDER_PLACED,
  URL_ORDER_UNASSIGNED,
  URL_ORDER_ASSIGNED,
  URL_ORDER_REPORT_MANAGER,
  URL_ORDER_CALLBACK_MANAGER,
  URL_ORDER_VISIT_SCHEDULE,
  URL_ORDER_EXAM_REVIEW,
} from 'constant/path/workflow';
import { LayoutSideTitle } from 'components/layout';
import { Button } from 'components/button';
import UserProfileStore from 'stores/UserProfileStore';
import { URL_EQUIPMENT_CHECKOUT } from 'constant/path/workflow';

const CHILD_PAGES = [
  { url: URL_ORDER_PLACED, title: 'Placed Orders', id: IDS.ORDER_PLACE.PAGE },
  {
    url: URL_ORDER_UNASSIGNED,
    title: 'Unassigned Orders',
    id: IDS.ORDER_UNASSIGNED.PAGE,
  },
  {
    url: URL_ORDER_ASSIGNED,
    title: 'Assigned Orders',
    id: IDS.ORDER_ASSIGNED.PAGE,
  },
  {
    url: URL_ORDER_REPORT_MANAGER,
    title: 'Report Manager',
    id: IDS.ORDER_REPORT_MNG.PAGE,
  },
  {
    url: URL_ORDER_CALLBACK_MANAGER,
    title: 'Callback Manager',
    id: IDS.ORDER_CALLBACK_MNG.PAGE,
  },
  {
    url: URL_ORDER_VISIT_SCHEDULE,
    title: 'Visit Schedule',
    id: IDS.ORDER_VISIT_SCHEDULER.PAGE,
  },
  {
    url: URL_ORDER_EXAM_REVIEW,
    title: 'Exam Review',
    id: IDS.ORDER_REVIEW.PAGE,
  },
];

interface PropsType extends RouteComponentProps, PropsTypeWitDivisionSettings {}

const OrderBase = ({ isDivisionEnabled, history }: PropsType) => {
  const [isTabVisible, toggleTabsVisibility] = useState<boolean>(true);

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [additionalButtons, setAdditionalButtons] =
    useState<React.ReactChild>(null);

  const tabsList = useMemo(() => {
    const availableTabs = CHILD_PAGES.filter((option) =>
      AccessUtils.checkAccess(option.id)
    );

    const tabsList = availableTabs.map(({ title, url }) => ({
      label: title,
      url,
    }));

    return <Tabs tabsList={tabsList} />;
  }, []);

  const isBtnProceedVisible = useMemo(
    () => UserProfileStore.getUserType() === 'T',
    []
  );

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  return (
    <>
      {isTabVisible && (
        <LayoutSideTitle title="Order Workflow">
          {isBtnProceedVisible && (
            <Button
              text="Proceed"
              onClick={() => history.push(URL_EQUIPMENT_CHECKOUT)}
            />
          )}
          {additionalButtons}
          <Button
            variant="default"
            text={isFilterVisible ? 'Hide Filter' : 'Show Filter'}
            onClick={handleToggleFilter}
          />
        </LayoutSideTitle>
      )}
      <div className="orders-page">
        {isTabVisible && tabsList}
        <Switch>
          <AccessRoute
            exact
            pageId={IDS.ORDER_PLACE.PAGE}
            path={URL_ORDER_PLACED}
            render={(props: any) => (
              <PlacedOrderContainer
                {...props}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                setAdditionalButtons={setAdditionalButtons}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
                isDosFilterAdvanced={true}
              />
            )}
          />
          <AccessRoute
            exact
            pageId={IDS.ORDER_UNASSIGNED.PAGE}
            path={URL_ORDER_UNASSIGNED}
            render={(props: any) => (
              <UnassignedOrdersContainer
                {...props}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                setAdditionalButtons={setAdditionalButtons}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
          <AccessRoute
            exact
            pageId={IDS.ORDER_ASSIGNED.PAGE}
            path={URL_ORDER_ASSIGNED}
            render={(props: any) => (
              <AssignedOrdersContainer
                {...props}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                setAdditionalButtons={setAdditionalButtons}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
          <AccessRoute
            exact
            pageId={IDS.ORDER_REPORT_MNG.PAGE}
            path={URL_ORDER_REPORT_MANAGER}
            render={(props: any) => (
              <ReportManagerContainer
                {...props}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                setAdditionalButtons={setAdditionalButtons}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
          <AccessRoute
            exact
            pageId={IDS.ORDER_CALLBACK_MNG.PAGE}
            path={URL_ORDER_CALLBACK_MANAGER}
            render={(props: any) => (
              <CallbackManagerContainer
                {...props}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                setAdditionalButtons={setAdditionalButtons}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
          <AccessRoute
            exact
            pageId={IDS.ORDER_VISIT_SCHEDULER.PAGE}
            path={URL_ORDER_VISIT_SCHEDULE}
            render={(props: any) => (
              <VisitScheduleContainer
                {...props}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                setAdditionalButtons={setAdditionalButtons}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
          <AccessRoute
            exact
            pageId={IDS.ORDER_REVIEW.PAGE}
            path={URL_ORDER_EXAM_REVIEW}
            render={(props: any) => (
              <ExamReviewContainer
                {...props}
                isDivisionEnabled={isDivisionEnabled}
                isFilterVisible={isFilterVisible}
                setAdditionalButtons={setAdditionalButtons}
                toggleFilterVisibility={handleToggleFilter}
                toggleTabsVisibility={toggleTabsVisibility}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export default withDivisionSettings(OrderBase);
