import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DropDownExamType } from 'components/project/dropdown/ExamTypeDropdown';
import { DropDownPlaceOfService } from 'components/project/dropdown/PlaceOfServiceDropdown';
import { DropDownState } from 'components/project/dropdown/StateDropdown';
import { DropdownLocation } from 'components/project/dropdown/LocationDropdown';
import DropDownTechnologist from 'components/project/dropdown/TechnologistDropDown';
import { DropDownOrderType } from 'components/project/dropdown/OrderType';
import { DropdownProviderType } from 'components/project/dropdown/ProviderTypeDropdown';
import { DropdownPayerName } from 'components/project/dropdown/PayerNameDropdown';
import { DropDownFacility } from 'components/project/dropdown/FacilityDropdown';
import { DropdownDivision } from 'components/project/dropdown/Division';
import { DateRange } from 'components/form/dateRange';
import { Input } from 'components/form/textField';
import { InputCalendar } from 'components/form/inputCalendar';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import { calcDateRange } from 'utils/calcDateRange';

export const filterDefaultValues: FilterType = {
  lastName: '',
  firstName: '',
  examType: [],
  providerType: [],
  facility: [],
  state: [],
  location: [],
  dob: '',
  orderType: [],
  technologist: 0,
  pos: [],
  ...calcDateRange('M'),
  period: 'M',
  payer: [],
  eligibility: '',
  division: 0,
};

export class FilterType {
  lastName: string;
  firstName: string;
  examType: number[];
  providerType: string[];
  facility: number[];
  state: number[];
  location: number[];
  dob: string;
  orderType: string[];
  technologist: number;
  pos: number[];
  dosStart: string;
  dosEnd: string;
  period: 'A' | 'TD' | 'W' | 'M';
  payer: number[];
  eligibility: 'I' | 'C' | 'W' | 'P' | '';
  division: number;
}

interface PropsType {
  isVisible: boolean;
  fetching: boolean;
  defaultValues: FilterType;
  onApplyFilter: (filter: FilterType) => void;
}

const dropdownAttributes = {
  isMulti: true,
  isStyleDependsOnValue: true,
};

const commonClassName = 'col-sm-6 col-md-3';

const Filter = ({
  isVisible,
  fetching,
  defaultValues,
  onApplyFilter,
}: PropsType) => {
  const { control, reset, handleSubmit } = useForm<FilterType>();

  const handleClickReset = () => {
    const filterValues = {
      ...filterDefaultValues,
      eligibility: defaultValues.eligibility,
    };
    reset(filterValues);
    onApplyFilter(filterValues);
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form
      className={`row${isVisible ? '' : ' d-none'}`}
      onSubmit={handleSubmit(onApplyFilter)}>
      <Input
        name="lastName"
        label="Last Name"
        className={commonClassName}
        control={control}
      />
      <Input
        name="firstName"
        label="First Name"
        className={commonClassName}
        control={control}
      />
      <DropDownExamType
        name="examType"
        label="Modality"
        optionsForOrderOnly
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <DropdownProviderType
        name="providerType"
        label="Provider Type"
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <div />
      <DropDownFacility
        name="facility"
        label="Facility"
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <DropDownState
        name="state"
        label="State"
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <DropdownLocation
        name="location"
        label="Location"
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <DropdownDivision
        name="division"
        label="Division"
        className={commonClassName}
        control={control}
      />
      <InputCalendar
        name="dob"
        label="DOB"
        className={commonClassName}
        control={control}
      />
      <DropDownOrderType
        name="orderType"
        label="Order Type"
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <DropDownTechnologist
        name="technologist"
        label="Technologist"
        className={commonClassName}
        control={control}
      />
      <DropDownPlaceOfService
        name="pos"
        label="Place of Service"
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <DropdownPayerName
        name="payer"
        label="Primary Payer"
        {...dropdownAttributes}
        className={commonClassName}
        control={control}
      />
      <DateRange
        nameFrom="dosStart"
        nameTo="dosEnd"
        nameRadio="period"
        radioClassName="col-sm-12 col-md-3 col-lg-auto"
        control={control}
      />
      <ControlsLayout alignX="auto">
        <Button
          variant="warning"
          text="Reset"
          disabled={fetching}
          onClick={handleClickReset}
        />
        <Button text="Apply" type="submit" disabled={fetching} />
      </ControlsLayout>
    </form>
  );
};

export default memo(Filter);
